'use client';

import React, {useState} from 'react';
import {ArrayFieldSchema, CommonContainerWidgetProps} from '@/app/components/form/types';
import {renderFormLayout} from '@/app/components/form/util/renderer';
import useTranslations from '@/hooks/translations';

interface RepeaterWidgetProps extends CommonContainerWidgetProps {
    field: ArrayFieldSchema
}

export default function RepeaterWidget({name, field, formSchema, state}: RepeaterWidgetProps) {
    const {tc} = useTranslations();

    const repeaterMin = field.options.repeaterOptions?.repeaterMin ?? 1;
    const repeaterMax = field.options.repeaterOptions?.repeaterMax ?? 0;
    const addBlockCounter = field.options.repeaterOptions?.addBlockCounter ?? false;
    const blockLabel = field.options.repeaterOptions?.blockLabel;

    const addLabel = tc(field.options.repeaterOptions?.labelAddBlock ?? 'form.repeater.add.default');
    const removeLabel = tc(field.options.repeaterOptions?.labelRemoveBlock ?? 'form.repeater.remove.default');

    const [repeatItems, setRepeatItems] = useState<number[]>(Array(repeaterMin).fill(null).map((e, i) => i));

    const onClickAdd = () => {

        if (repeatItems.length >= repeaterMax && repeaterMax > 0) {
            return;
        }

        setRepeatItems([...repeatItems, repeatItems.length ? repeatItems.slice(-1)[0] + 1 : repeaterMin]);
    }

    const onClickRemove = (removeIndex: number) => {

        if (repeatItems.length <= repeaterMin) {
            return;
        }

        setRepeatItems(repeatItems.filter((e, i) => i !== removeIndex));
    }

    return (
        <>
            <h5 className="mb-1">{field.title}</h5>
            {repeatItems.length < repeaterMax || repeaterMax === 0 && (
                <button className="btn btn-primary my-2" type="button" onClick={onClickAdd}>{addLabel}</button>
            )}
            {repeatItems.map((e, i) => {
                return (
                    <div className="my-2" key={e}>
                        {(addBlockCounter || blockLabel) && (
                            <strong>{blockLabel ? tc(blockLabel) : ''} {e + 1}</strong>
                        )}
                        {renderFormLayout(formSchema, field.items.properties, state, `${name}.${e}.`)}

                        {repeatItems.length > repeaterMin && (
                            <button className="btn btn-secondary my-2" onClick={() => onClickRemove(i)}
                                    type="button">{removeLabel}</button>
                        )}
                    </div>
                )
            })}
        </>
    )
}
