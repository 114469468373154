'use client';

import React from 'react';
import {FieldSchema} from '@/app/components/form/types';

interface LabelWidgetProps {
    field: FieldSchema,
    inline?: boolean
}

export default function LabelWidget({field, inline = false}: LabelWidgetProps) {
    return (
        <div className={inline ? 'form-label-inline' : 'form-label'}>
            {field.title}
            {field.options.initialConstraints && field.options.initialConstraints.includes('not_blank') && (
                <> *</>
            )}
        </div>
    )
}
