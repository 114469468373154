'use client';

import {ProductCategory} from '@/types/objects/product';
import {useEffect, useMemo, useState} from 'react';

interface ProductOverviewListHelperProps {
    selectedCategory: number | null;
    initialLoaded: boolean;
    categories: ProductCategory[];
}

export default function ProductOverviewListHelper({selectedCategory, categories, initialLoaded}: ProductOverviewListHelperProps) {
    const [defaultPageHeaderTitle, setDefaultPageHeaderTitle] = useState<string>('');
    const [defaultPageHeaderDescriptionContent, setDefaultPageHeaderDescriptionContent] = useState<string>('');

    const updatePageHeader = (title: string, description: string) => {
        document.querySelector('.page-header-title')!.textContent = title;
        document.querySelector('.page-header-description')!.innerHTML = description;
    }

    useEffect(() => {
        if (defaultPageHeaderTitle || defaultPageHeaderDescriptionContent) {
            if (selectedCategory) {
                const cat = categories.find((category) => category.id === selectedCategory);

                if (cat) {
                    updatePageHeader(cat.title, cat.teaserText || '');
                } else {
                    updatePageHeader(defaultPageHeaderTitle, defaultPageHeaderDescriptionContent);
                }
            } else {
                updatePageHeader(defaultPageHeaderTitle, defaultPageHeaderDescriptionContent);
            }
        } else {
            setDefaultPageHeaderTitle(document.querySelector('.page-header-title')?.textContent || '');
            setDefaultPageHeaderDescriptionContent(document.querySelector('.page-header-description')?.innerHTML || '');
        }
    }, [selectedCategory]);

    return <></>;
}