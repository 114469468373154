import {Popover, PopoverContent, PopoverTrigger} from '@/components/ui/popover';
import {format} from 'date-fns';
import {Calendar} from '@/components/ui/calendar';
import React, {useEffect, useMemo, useState} from 'react';
import ChevronDownIcon from '@/images/icons/chevron-down.svg';
import CalendarIcon from '@/images/icons/calendar.svg';
import {motion} from 'framer-motion';
import useTranslations from '@/hooks/translations';
import {DateRange} from 'react-day-picker';
import {getDatePickerLocale} from '@/util/date';

interface NewsOverviewPublicationDateFilterProps {
    date: DateRange | undefined;
    setDate: (date: DateRange | undefined) => void;
}

export default function NewsOverviewPublicationDateFilter({date, setDate}: NewsOverviewPublicationDateFilterProps) {
    const {tc, locale} = useTranslations();
    const [tempDate, setTempDate] = React.useState<DateRange | undefined>(date);

    const [popOverOpen, setPopOverOpen] = useState<boolean>(false);
    const [filterOpen, setFilterOpen] = useState<boolean>(true);
    const datePickerLocale = useMemo(() => getDatePickerLocale(locale as 'de' | 'en' | 'pl' | 'cs'), [locale]);

    const handleReset = () => {
        setTempDate(undefined);
        setDate(undefined);
        setPopOverOpen(false);
    }

    const handleApply = () => {
        setDate(tempDate);
        setPopOverOpen(false);
    }

    useEffect(() => {
        if (date !== tempDate) {
            setTempDate(date);
        }
    }, [date]);

    return (
        <div className="bg-grey-100 rounded-lg overflow-hidden">
            <button onClick={() => setFilterOpen(!filterOpen)}
                    className="flex justify-between w-full items-center px-4 py-3">
                <div className="text-grey-500 small font-semibold">
                    {tc('app.news.overview.filter.publication_date')}
                </div>
                <div className="shrink-0">
                    <ChevronDownIcon className={`w-6 h-6 text-primary-text transition-all ${filterOpen ? 'rotate-180' : ''}`}/>
                </div>
            </button>
            <motion.div className="transform transition-transform origin-top ease-out px-4"
                        initial={{height: 0}}
                        animate={{height: filterOpen ? 'auto' : 0}}
                        transition={{duration: 0.25, ease: 'easeIn'}}>
                <div className="pb-3">
                    <Popover open={popOverOpen} onOpenChange={(o) => setPopOverOpen(o)}>
                        <PopoverTrigger asChild>
                            <button
                                className="w-full flex justify-between items-center transition-all text-left py-3 px-4 border text-base border-grey-200 rounded-md data-[state=open]:border-primary data-[state=open]:outline data-[state=open]:outline-1 data-[state=open]:outline-primary">
                                <div className="flex-1 truncate text-grey-400">
                                    {date?.from ? (
                                        date.to ? (
                                            <>
                                                {format(date.from, 'dd.MM.y')} -{' '}
                                                {format(date.to, 'dd.MM.y')}
                                            </>
                                        ) : (
                                            format(date.from, 'dd.MM.y')
                                        )
                                    ) : (
                                        <>{tc('app.news.overview.filter.publication_date.choose')}</>
                                    )}
                                </div>
                                <CalendarIcon className="shrink-0 w-6 h-6 text-primary-text"/>
                            </button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto" align={'start'}>
                            <Calendar mode="range"
                                      defaultMonth={tempDate?.from}
                                      disabled={{ after: new Date()}}
                                      selected={tempDate}
                                      onSelect={setTempDate}
                                      locale={datePickerLocale}
                                      numberOfMonths={1}
                            />
                            <div className="px-6 py-3 border-t border-t-grey-200 flex gap-4 justify-between items-center">
                                <button className="btn text-primary-text flex-1"
                                        onClick={handleReset}>
                                    {tc('app.news.overview.filter.publication_date.reset')}
                                </button>
                                <button className="btn btn-primary flex-1"
                                        onClick={handleApply}>
                                    {tc('app.news.overview.filter.publication_date.apply')}
                                </button>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
            </motion.div>
        </div>
    )
}