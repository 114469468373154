import Link from 'next/link';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import {InspirationListItem} from '@/types/objects/inspiration';

interface InspirationTileProps {
    data: InspirationListItem;
}

export default function InspirationTile({data}: InspirationTileProps) {
    return (
        <div className={`inspiration-tile w-full bg-grey-100 rounded-lg relative ${data.renderClass || 'aspect-square'}`}>
            {data.id && (
                <Link href={data.detailUrl} className="absolute inset-0 group">
                    <PimcoreImage
                        image={data.teaserImage}
                        className="h-full w-full rounded-lg object-cover object-center"
                    />
                    <div className="absolute inset-0 flex flex-col justify-end p-6">
                        <div className="absolute inset-x-0 bottom-0 h-32 group-hover:h-[80%] rounded-b-lg transition-all duration-75 ease-out bg-gradient-to-b from-transparent to-black/80 group-hover:to-secondary-100"></div>
                        <span className="z-10 font-bold text-white">
                            {data.title}
                        </span>
                        <span
                            className="z-10 overflow-hidden line-clamp-2 text-sm text-white duration-75 ease-out transition-[max-height] lg:max-h-0 lg:group-hover:max-h-10">
                            {data.teaserText}
                        </span>
                    </div>
                </Link>
            )}
        </div>
    )
}