import {useDropzone} from 'react-dropzone';
import {useEffect, useRef, useState} from 'react';
import useTranslations from '@/hooks/translations';
import UploadIcon from '@/images/icons/upload.svg';

const TextWrapper = ({text}: { text: string }) => {
    const regex = /%([^%]+)%/g;

    const parts = text.split(regex).map((part, index) => {
        if (index % 2 === 1) {
            return <span className="text-blue-100 underline" key={index}>{part}</span>;
        }

        return part;
    });

    return <>{parts}</>;
}

export default function ProductRequestFileUpload() {
    const [dragOver, setDragOver] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {tc} = useTranslations();

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/gif': [],
            'image/jpeg': [],
            'image/png': [],
            'image/webp': [],
        },
        maxFiles: 1,
        maxSize: Infinity,
        onDrop: () => setDragOver(false),
        onDragOver: () => setDragOver(true),
        onDragLeave: () => setDragOver(false),
    });

    useEffect(() => {
        if (fileInputRef.current) {
            if (acceptedFiles.length > 0) {
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(acceptedFiles[0]);
                fileInputRef.current.files = dataTransfer.files;
            } else {
                fileInputRef.current.value = '';
            }
        }
    }, [acceptedFiles]);

    return (
        <div>
            <h5>{tc('app.product_request_form.file_upload')}</h5>

            <div
                className={`rounded-lg border border-dashed border-grey-200 bg-grey-150 cursor-pointer hover:border-solid p-10 flex flex-col items-center justify-center gap-3 ${dragOver ? 'border-solid' : 'border-dashed'}`} {...getRootProps()}>
                <div>
                    <UploadIcon className="w-6 h-6 text-blue-200"/>
                </div>
                <div className="text-sm">
                    <TextWrapper text={tc('app.product_request_form.file_upload.drag_and_drop')}/>
                </div>
            </div>

            <input ref={fileInputRef} type="file" name="files" className="hidden" />
            <input type="file" {...getInputProps()} />
            {acceptedFiles.length > 0 && (
                <div className="mt-2">
                    {acceptedFiles.map((file, i) => (
                        <div className="u-border-gradient-landscape overflow-hidden" key={i}>
                            <div className="px-3 py-4 bg-white rounded text-sm font-bold text-blue-300 hyphens-auto">
                                {file.name}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}