'use client';

import {MutableRefObject, useEffect, useState} from 'react';
import {AvailableSearchEndpoints, SearchAggregation, SearchFilterChip} from '@/types/search';
import {getSearchItems} from '@/api/search';

interface ISearch<T> {
    items: T[];
    loading: boolean;
    totalCount: number;
    chips: SearchFilterChip[];
    filterOpen: boolean;
    aggregations: SearchAggregation[];
    setFilterOpen: (open: boolean) => void;
    handleReset: (additionalSearchQuery?: string) => void;
    handlePagination: (additionalSearchQuery?: string) => void;
    handleFilter: (closeFilter: boolean, additionalSearchQuery?: string) => void;
    handleChipRemove: (chip: SearchFilterChip, additionalSearchQuery?: string) => void;
}

export default function useSearch<T>(
    searchEndpoint: AvailableSearchEndpoints,
    initialItems: T[],
    searchAggregations: SearchAggregation[],
    formRef: MutableRefObject<HTMLFormElement | null>,
    initialTotalCount: number,
    limit: number,
    order: 'asc' | 'desc',
    orderKey: string,
    excludedSearchAggregationKeys?: string[]
): ISearch<T> {
    const [totalCount, setTotalCount] = useState<number>(initialTotalCount);
    const [items, setItems] = useState<T[]>(initialItems);
    const [loading, setLoading] = useState<boolean>(false);
    const [chips, setChips] = useState<SearchFilterChip[]>([]);
    const [aggregations, setAggregations] = useState<SearchAggregation[]>(searchAggregations);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);

    useEffect(() => {
        document.body.style.overflow = filterOpen ? 'hidden' : '';
    }, [filterOpen]);

    const handleChipRemove = (chip: SearchFilterChip, additionalSearchQuery?: string) => {
        const formElement = formRef?.current;
        if (!formElement) {
            return;
        }

        const inputElement: HTMLInputElement | null = formElement.querySelector(`input[name="${chip.key}"][value="${chip.value}"]`) || null;

        if (inputElement) {
            inputElement.checked = false;
            const event = new Event('change', { bubbles: true });
            inputElement.dispatchEvent(event);
        }

        const queryString = createSearchQuery();

        handleSearch(queryString, false, false, false, additionalSearchQuery || '');
    }

    const handleReset = (additionalSearchQuery?: string) => {
        formRef?.current?.reset();

        setChips([]);

        handleSearch('', false, false, true, additionalSearchQuery || '');
    }

    const handlePagination = (additionalSearchQuery?: string) => {
        const queryString = createSearchQuery();

        handleSearch(queryString, true, false, false, additionalSearchQuery || '');
    }

    const handleFilter = (closeFilter: boolean, additionalSearchQuery?: string, returnFn?: () => void) => {
        const queryString = createSearchQuery();

        handleSearch(queryString, false, closeFilter, false, additionalSearchQuery || '').then(() => returnFn ? returnFn() : null);
    }

    const handleSearch = async (queryString: string, pagination: boolean, closeFilter: boolean, reset: boolean, additionalSearchQuery: string) => {
        setLoading(true);
        const newPage = pagination ? Math.floor(items?.length / limit) + 1 : 1;

        if (reset) {
            setChips([]);
        }

        if (closeFilter) {
            setFilterOpen(false);
        }

        window.history.pushState({}, '', `?${queryString}${additionalSearchQuery}`);
        const searchResponse = await getSearchItems<T>({
            searchEndpoint: searchEndpoint,
            limit: limit,
            page: newPage,
            order: order,
            orderKey: orderKey,
            searchQuery: `&${queryString}${additionalSearchQuery}`,
        });

        setTotalCount(searchResponse.totalCount);
        setItems(pagination ? [...items, ...searchResponse.hits] : searchResponse.hits);
        setAggregations((searchResponse.aggregations || [])?.filter((aggregation) => !(excludedSearchAggregationKeys || [])?.includes(aggregation.key || 'attributes')) || []);

        setLoading(false);
    }

    const createSearchQuery = (): string => {
        if (!formRef?.current) {
            setChips([]);
            return '';
        }

        const formData: FormData = new FormData(formRef.current) as FormData;
        createFilterChips(formData);

        return new URLSearchParams(formData as unknown as Record<string, string>).toString();
    }

    const createFilterChips = (formData: FormData): void => {
        const filterChips: SearchFilterChip[] = [];

        for (const [key, value] of formData.entries()) {
            if (typeof value !== 'string') {
                return;
            }

            const filterGroup = Object.values(searchAggregations).find((a) => a.values.find(v => v.value === parseInt(value)));
            const filterItem = filterGroup ? filterGroup.values.find(v => v.value === parseInt(value)) : null;

            if (!filterItem) {
                return;
            }

            filterChips.push({
                key: key,
                value: value as string,
                label: filterItem?.label || '',
            });
        }

        setChips(filterChips);
    }

    return {
        items: items,
        loading: loading,
        totalCount: totalCount,
        chips: chips,
        aggregations: aggregations,
        filterOpen: filterOpen,
        setFilterOpen: setFilterOpen,
        handleFilter: handleFilter,
        handlePagination: handlePagination,
        handleReset: handleReset,
        handleChipRemove: handleChipRemove
    }
}
