import {ProductCategory} from '@/types/objects/product';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';

interface ProductCategoryFilterProps {
    categories: ProductCategory[];
    toggleCategory: (id: number) => void;
    selectedCategory: number | null;
}

export default function ProductCategoryFilter({categories, toggleCategory, selectedCategory}: ProductCategoryFilterProps) {
    return (
        <div className="flex overflow-x-auto lg:grid lg:grid-cols-6 gap-4 pb-6 -mx-6 sm:-mx-10 px-6 sm:px-10 lg:mx-0 lg:px-0 lg:pb-0 lg:overflow-visible">
            {categories.map((category) => (
                <button onClick={() => toggleCategory(category.id)}
                        key={category.id}
                        className={`w-36 h-36 sm:h-auto sm:w-56 shrink-0 lg:w-auto p-4 bg-white rounded-lg flex flex-col justify-center sm:justify-start items-center gap-3 transition-all shadow border-2 ${selectedCategory === category.id ? 'border-primary' : 'border-transparent'}`}>
                    {category.image ? (
                        <PimcoreImage image={category.image}
                                      className="w-12 h-12 object-contain object-center rounded-md"/>
                    ) : <></>}
                    <span
                        className={`tiny strong ${selectedCategory === category.id ? 'text-primary' : 'text-blue-300'}`}>{category.title}</span>
                </button>
            ))}
        </div>
    )
}