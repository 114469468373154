import {MutableRefObject, useEffect} from 'react';

interface FilterHelperProps {
    formRef: MutableRefObject<HTMLFormElement | null>;
    handleFilter: (q: string) => void;
    additionalFilterHelperFn?: (searchParams: URLSearchParams) => void | string;
}

export default function FilterHelper({formRef, additionalFilterHelperFn, handleFilter}: FilterHelperProps) {
    useEffect(() => {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);

        let q = '';

        if (additionalFilterHelperFn) {
            const fnReturn = additionalFilterHelperFn(searchParams);
            q = fnReturn ? fnReturn : '';
        }

        const formElement = formRef?.current;
        if (!formElement) {
            return;
        }

        if (formRef?.current) {
            searchParams.forEach((value, key) => {
                const inputElement: HTMLInputElement | null = formElement?.querySelector(`input[name="${key}"][value="${value}"]`) || null;

                if (inputElement) {
                    inputElement.checked = true;
                }
            });
        }

        handleFilter(q);
    }, []);

    return <></>;
}