'use client';

import React from 'react';
import {CommonFormWidgetProps, StringFieldSchema} from '@/app/components/form/types';

interface HtmlTagWidgetProps extends CommonFormWidgetProps {
    field: StringFieldSchema
}

export default function HtmlTagWidget({field}: HtmlTagWidgetProps) {
    if(!field.options?.tag) {
        return;
    }

    return React.createElement(field.options?.tag, {}, field.title);
}
