'use client';

import screens from '../../tailwind.screens';
import {useEffect, useState} from 'react';

export type Breakpoint = keyof typeof breakpoints;
export const breakpoints = {
    xs: Number.parseInt(screens.xs),
    ty: Number.parseInt(screens.ty),
    sm: Number.parseInt(screens.sm),
    md: Number.parseInt(screens.md),
    lg: Number.parseInt(screens.lg),
    xl: Number.parseInt(screens.xl),
};

export const useBreakpoint = (breakpoint: keyof typeof breakpoints): boolean => {
    const mediaMatch = window.matchMedia(`(min-width: ${breakpoints[breakpoint]}px)`);
    const [matches, setMatches] = useState<boolean>(mediaMatch.matches);

    useEffect(() => {
        const handler = (event: MediaQueryListEvent) => setMatches(event.matches);
        mediaMatch.addEventListener('change', handler);

        return () => mediaMatch.removeEventListener('change', handler);
    }, []);

    return matches;
}