'use client';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import React, {useState} from 'react';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import Lightbox from '@/app/components/util/Lightbox/Lightbox';
import SliderButtons from '@/app/components/util/Slider/SliderButtons';
import {GalleryElement} from '@/app/components/cms/content/VideoGallery/VideoGallery.types';
import {PimcoreImage as PimcoreImageInterface, PimcoreVideoElement} from '@/types/pimcore';
import VideoInline from '@/app/components/cms/content/Video/VideoInline';
import {VideoContentElement} from '@/app/components/cms/content/Video/Video.types';

interface GallerySliderProps {
    elements: Array<GalleryElement>;
    withLightbox?: boolean;
    hidePagination?: boolean;
    forceSliderButtons?: boolean;
    startSlide?: number;
}

export default function VideoGallerySlider({forceSliderButtons, elements, startSlide, withLightbox, hidePagination}: GallerySliderProps) {
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
    const [lightboxImage, setLightboxImage] = useState<number>(-1);

    const openLightbox = (index: number) => {
        setLightboxImage(index);
        setLightboxOpen(true);
    }

    const closeLightbox = () => {
        setLightboxImage(-1);
        setLightboxOpen(false);
    }

    const hasDescriptions = elements.some(element => !!element.gallery.title);

    return (
        <>
            {elements?.length > 0 && (
                <>
                    <div className={`relative w-full rounded-lg overflow-hidden ${hidePagination ? 'hide-pagination' : ''}`}>
                        <Swiper spaceBetween={12}
                                slidesPerView={1}
                                initialSlide={startSlide || 0}
                                loop={true}
                                pagination={{
                                    dynamicBullets: !hidePagination,
                                }}
                                modules={!hidePagination ? [Pagination] : []}
                                className={`w-full h-full gallery-slider ${hasDescriptions && 'has-descriptions'}`}>

                            {(forceSliderButtons || withLightbox) ? (
                                <SliderButtons withLightbox={forceSliderButtons || withLightbox} hasDescriptions={hasDescriptions}/>
                            ) : <></>}

                            {elements.map((image, index) => (
                                <SwiperSlide key={index} onClick={() => withLightbox ? openLightbox(index) : null}>

                                    {image.type === 'image' && (
                                        <PimcoreImage
                                            image={image.lightbox as PimcoreImageInterface}
                                            className="h-auto aspect-[16/9] w-full rounded-lg object-cover object-center"
                                        />
                                    )}

                                    {image.type === 'video' && (
                                        <VideoInline element={{
                                            elementContext: {
                                                configuration: {
                                                    areaId: 'video',
                                                    additionalClassesData: [],
                                                },
                                                data: {
                                                    autoplay: true,
                                                    playInLightbox: 'false',
                                                    video: image.lightbox as PimcoreVideoElement,
                                                },
                                            },
                                        } as VideoContentElement} autoplay={false}/>
                                    )}

                                    {!!image.lightbox.title && (
                                        <span className="mt-2 block text-description text-white">
                                            {image.lightbox.title}
                                        </span>
                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    {withLightbox && (
                        <ClientOnly>
                            <Lightbox open={lightboxOpen} setOpen={closeLightbox}>
                                <div className="flex h-full w-full items-center justify-center">
                                    <div className="container flex h-4/5 max-w-4xl flex-col justify-center">
                                        <VideoGallerySlider
                                            elements={elements}
                                            withLightbox={false}
                                            startSlide={lightboxImage}
                                        />
                                    </div>
                                </div>
                            </Lightbox>
                        </ClientOnly>
                    )}
                </>
            )}
        </>
    )
}
