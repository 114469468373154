'use client';

import React from 'react';
import {CommonFormWidgetProps, StringFieldSchema} from '@/app/components/form/types';
import LabelWidget from '@/app/components/form/widgets/LabelWidget';
import {transformAttributes} from '@/app/components/form/util/helper';

interface TextareaWidgetProps extends CommonFormWidgetProps {
    field: StringFieldSchema
}

export default function TextareaWidget({name, field}: TextareaWidgetProps) {
    return (
        <label>
            <LabelWidget field={field}/>
            <textarea
                className="form-textarea"
                name={name}
                id={name}
                defaultValue={field.default}
                {...transformAttributes(field.options.attr)}
            />
        </label>
    )
}
