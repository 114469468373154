'use client';

import {AccordionContentElement} from '@/app/components/cms/content/Accordion/Accordion.types';
import React, {useEffect, useRef, useState} from 'react';
import ClientOnly from "@/app/components/util/ClientOnly/ClientOnly";
import AccordionTabHelper from "@/app/components/cms/content/Accordion/AccordionTabHelper";
import Accordion from "@/app/components/cms/content/Accordion/Accordion";

interface TabProps {
    element: AccordionContentElement;
    childrenElements: React.ReactNode[];
}

export default function Tab({element, childrenElements}: TabProps) {
    const scrollContainerRef = useRef<HTMLUListElement>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [isTablet, setIsTablet] = useState<boolean>(false);

    const onElementClick = (ev: React.MouseEvent<HTMLButtonElement>, index: number) => {
        const container = scrollContainerRef?.current;

        if (!container) {
            return;
        }
        const targetElement = ev.currentTarget;

        const containerWidth = container.offsetWidth;
        const targetElementWidth = targetElement.offsetWidth;
        const scrollLeftValue = targetElement.offsetLeft - (containerWidth - targetElementWidth) / 2;
        container.scrollLeft = Math.max(0, Math.min(scrollLeftValue, container.scrollWidth - containerWidth));

        setActiveIndex(index);
    }

    return (
        <>
            <ClientOnly>
                <AccordionTabHelper setIsTablet={setIsTablet}/>
            </ClientOnly>
            {isTablet ? (
                <Accordion element={element} childrenElements={childrenElements} />
            ) : (
                <div className="toolbox-element toolbox-accordion">
                    <ul>
                        <ul className={`flex justify-between border-b-grey-200 border-b nowrap mb-3 overflow-x-auto no-scrollbar`}
                            ref={scrollContainerRef}>
                            {element.elements.ac_panel.elements.map((tab, index) => (
                                <li className={`overflow-y-hidden hover:bg-grey-100 w-full justify-between items-center cursor-pointer relative ${activeIndex === index ? 'text-primary-text' : 'bg-white'}`}
                                    key={index}>
                                    <button
                                        className={`mt-1 font-bold mb-0 text-sm w-full truncate whitespace-nowrap text-blue-200 py-1 px-3 h-12`}
                                        onClick={(ev) => onElementClick(ev, index)}>
                                        {tab.ac_name.elementContext.data.ac_name}
                                    </button>
                                    {activeIndex === index && (
                                        <span
                                            className="block w-6 h-2 bg-primary absolute -bottom-1 -translate-x-2/4 rounded-x left-2/4"></span>
                                    )}
                                </li>
                            ))}
                        </ul>
                        {element?.elements?.ac_panel?.elements.map((tab, index) => (
                            <li className={`bg-white overflow-hidden transition-all duration-250`}
                                key={index}>
                                <div
                                    className={`overflow-hidden ${activeIndex === index ? 'block' : 'hidden'}`}>
                                    <div className="py-3 [&_*:last-child]:mb-0">
                                        {(tab?.ac_data?.elements && tab?.ac_data?.elements?.length > 0) &&
                                            childrenElements[index]
                                        }
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}
