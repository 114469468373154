'use client'

import React, {useEffect, useRef, useState} from 'react';

interface Props {
    documentId: number;
}

export default function IframeContent({documentId}: Props) {
    const ref = useRef<HTMLIFrameElement>(null);
    const [height, setHeight] = useState<number>(0);

    const onLoad = () => {
        const iframeElement = ref?.current;
        const iframeContent = iframeElement?.contentWindow?.document.body;

        if(!iframeElement || !iframeContent) {
            return;
        }

        const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                setHeight(ref?.current?.contentWindow?.document.body.offsetHeight ?? 0);
            });
        });
        resizeObserver.observe(iframeContent);
    };

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <iframe
            ref={ref}
            onLoad={onLoad}
            className='w-full max-h-screen'
            src={`/de/iframe-content/${documentId}`} // locale
            height={`${height}px`}
        ></iframe>
    );
}
