'use client';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import {ProductSearchItem} from '@/types/objects/product';
import {DocumentProperty} from '@/types/pimcore';
import ProductItem from '@/app/components/product/ProductItem';
import Link from 'next/link';
import useTranslations from '@/hooks/translations';

interface ProductTeaserSliderProps {
    items: ProductSearchItem[];
    overviewPageProperty: DocumentProperty | undefined;
}

export default function ProductTeaserSlider({items, overviewPageProperty}: ProductTeaserSliderProps) {
    const {tc} = useTranslations();
    return (
        <div className="-mx-6 sm:-mx-10 md:mx-0">
            <Swiper
                pagination={{
                    dynamicBullets: true,
                    clickable: true,
                }}
                spaceBetween={16}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        loop: items?.length > 1,
                    },
                    768: {
                        slidesPerView: 2,
                        loop: items?.length > 2,
                    },
                    992: {
                        slidesPerView: 4,
                        loop: items?.length > 4,
                    },
                }}
                modules={[Pagination]}
                className="!pb-10 mb-8 md:mb-0 md:!pb-12 relative swiper-inverted !px-6 sm:!px-10 md:!px-0 product-teaser-slider">
                {items.map((item) => (
                    <SwiperSlide key={item.id}>
                        <ProductItem data={item} />
                    </SwiperSlide>
                ))}
            </Swiper>
            {overviewPageProperty ? (
                <div className="flex justify-center items-center px-6 sm:px-10">
                    <Link href={overviewPageProperty.element?.fullPath || ''}
                          className="btn btn-secondary"
                          title={tc('app.product_teaser.view_all')}>
                        {tc('app.product_teaser.view_all')}
                    </Link>
                </div>
            ) : <></>}
        </div>
    )
}