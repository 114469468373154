import {useBreakpoint} from "@/hooks/breakpoint";
import {useEffect} from "react";

interface AccordionTabHelperProps {
    setIsTablet: (isTablet: boolean) => void;
}

export default function AccordionTabHelper({setIsTablet}: AccordionTabHelperProps) {
    const isMd = useBreakpoint('md');

    useEffect(() => {
        setIsTablet(!isMd);
    }, [isMd]);

    return <></>
}