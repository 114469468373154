import React from 'react';

import {
    ArrayFieldSchema,
    BooleanFieldSchema, CommonContainerWidgetProps,
    CommonFormWidgetProps,
    FieldSchema, FormSchema, FormState,
    IntegerFieldSchema,
    ObjectFieldSchema,
    StringFieldSchema,
} from '@/app/components/form/types';
import HoneypotWidget from '@/app/components/form/widgets/HoneypotWidget';
import HiddenWidget from '@/app/components/form/widgets/HiddenWidget';
import TextWidget from '@/app/components/form/widgets/TextWidget';
import TextareaWidget from '@/app/components/form/widgets/TextareaWidget';
import SubmitWidget from '@/app/components/form/widgets/SubmitWidget';
import IntegerWidget from '@/app/components/form/widgets/IntegerWidget';
import ChoiceWidget from '@/app/components/form/widgets/ChoiceWidget';
import CheckboxWidget from '@/app/components/form/widgets/CheckboxWidget';
import HtmlTagWidget from '@/app/components/form/widgets/HtmlTagWidget';
import InstructionsWidget from '@/app/components/form/widgets/InstructionsWidget';
import SnippetWidget from '@/app/components/form/widgets/SnippetWidget';
import DateWidget from '@/app/components/form/widgets/DateWidget';
import TimeWidget from '@/app/components/form/widgets/TimeWidget';
import RepeaterWidget from '@/app/components/form/widgets/container/RepeaterWidget';
import FieldsetWidget from '@/app/components/form/widgets/container/FieldsetWidget';
import ObjectWidget from '@/app/components/form/widgets/container/ObjectWidget';
import FormWidgetWrapper from '@/app/components/form/FormWidgetWrapper';


export const renderFormLayout = (formSchema: FormSchema, properties: Record<string, FieldSchema>, state: FormState, namePrefix: string = '') => {
    return (
        <div className="grid grid-cols-2 gap-4">
            {Object.entries(properties).map(([name, field], index) => {
                const fieldName = namePrefix ? `${namePrefix}${name}` : name;
                return (
                    <div className={`${field.options.layout === 'form-template-full' ? 'col-span-2' : 'col-span-2 md:col-span-1'} ${field.options.widget === 'hidden' ? 'hidden' : ''}`}
                         key={index}>
                        <FormWidgetWrapper fieldName={fieldName} field={field} fieldViolations={state.fieldViolations}>
                            {renderFormWidgets(formSchema, fieldName, field, state)}
                        </FormWidgetWrapper>
                    </div>
                );
            })}
        </div>
    )
}

export const renderFormWidgets = (formSchema: FormSchema, name: string, field: FieldSchema, state: FormState) => {

    const commonProps = {
        name: name,
    } as CommonFormWidgetProps;

    const containerProps = {...commonProps, formSchema: formSchema, state: state} as CommonContainerWidgetProps

    switch (field.options.widget) {
        // container
        case 'field_set_container':
            return <FieldsetWidget {...{...containerProps, field: field as ArrayFieldSchema}} />
        case 'repeater_container':
            return <RepeaterWidget {...{...containerProps, field: field as ArrayFieldSchema}} />
        case 'date_choice':
        case 'time_choice':
        case 'birthday_choice':
        case 'date_text':
        case 'time_text':
        case 'birthday_text':
        case 'date_time_':
            return <ObjectWidget {...{...containerProps, field: field as ObjectFieldSchema}} />

        // default fields
        case 'text':
        case 'email':
            return <TextWidget {...{...commonProps, field: field as StringFieldSchema}} />;
        case 'textarea':
            return <TextareaWidget {...{...commonProps, field: field as StringFieldSchema}} />;
        case 'submit':
            return <SubmitWidget {...{...commonProps, field: field as BooleanFieldSchema}} />;
        case 'integer':
            return <IntegerWidget {...{...commonProps, field: field as IntegerFieldSchema}} />;
        case 'country':
        case 'choice':
            return <ChoiceWidget {...{...commonProps, field: field as FieldSchema}} />;
        case 'checkbox':
            return <CheckboxWidget {...{...commonProps, field: field as BooleanFieldSchema}} />
        case 'birthday_single_text':
        case 'date_single_text':
            return <DateWidget {...{...commonProps, field: field as StringFieldSchema}} />
        case 'time_single_text':
            return <TimeWidget {...{...commonProps, field: field as StringFieldSchema}} />
        case 'hidden':
            return <HiddenWidget {...{...commonProps, field: field as StringFieldSchema}} />;

        // special fields
        case 'html_tag':
            return <HtmlTagWidget {...{...commonProps, field: field as StringFieldSchema}} />
        case 'instructions':
            return <InstructionsWidget {...{...commonProps, field: field as StringFieldSchema}} />
        case 'snippet':
            return <SnippetWidget {...{...commonProps, field: field as StringFieldSchema}} />
        case 'honeypot':
            return <HoneypotWidget {...{...commonProps, field: field as StringFieldSchema}} />;

        default:
            return (
                <div>
                    {process.env.NODE_ENV === 'development' ? `No valid form widget: ${field.options.widget}` : ''}
                </div>
            );
    }
};
