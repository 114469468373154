'use client';

import {AccordionContentElement} from '@/app/components/cms/content/Accordion/Accordion.types';
import React, {useEffect, useState} from 'react';
import ChevronUp from '@/images/icons/chevron-up.svg';
import {motion} from 'framer-motion';

interface AccordionProps {
    element: AccordionContentElement;
    childrenElements: React.ReactNode[];
}

export default function Accordion({element, childrenElements}: AccordionProps) {
    const initialConfig = element.elementContext.data.accordion_initial_config;
    let initialActiveState = [0];

    if (initialConfig !== 'first-open') {
        if (initialConfig === 'all-close') {
            initialActiveState = [];
        }
        if (initialConfig === 'all-open') {
            initialActiveState = element.elements.ac_panel.elements.map((accordion, index) => index);
        }
    }

    const [activeIndex, setActiveIndex] = useState<Array<number>>(initialActiveState);

    return (
        <div className="toolbox-element toolbox-accordion">
            <ul>
                {element?.elements?.ac_panel?.elements.map((accordion, index) => (
                    <li className={`mb-3 relative group hover:text-primary-text u-border-gradient-landscape overflow-hidden ${activeIndex.includes(index) ? 'text-primary-text' : ''}`}
                        key={index}>
                        <div className={`absolute inset-0 h-full w-full group-hover:gradient-hover-background ${activeIndex.includes(index) ? 'u-border-gradient-landscape gradient-hover-background' : 'bg-white'}`}></div>
                        <button
                            className={`relative w-full flex justify-between items-center cursor-pointer font-semibold py-4 px-6`}
                            onClick={() => setActiveIndex(prevState => prevState.includes(index) ? prevState.filter(i => i !== index) : [...prevState, index])}>
                            <h5 className="mb-0 text-blue-200 text-left hyphens-auto break-bw">
                                {accordion.ac_name.elementContext.data.ac_name}
                            </h5>
                            <div className="ml-4 shrink-0">
                                <div
                                    className={`transition-all duration-300 p-3 bg-grey-125 rounded-full text-primary-text ${activeIndex.includes(index) ? 'rotate-0' : 'rotate-180'}`}>
                                    <ChevronUp className="w-6 h-6"/>
                                </div>
                            </div>
                        </button>

                        <motion.div
                            className="transform transition-transform origin-top ease-out relative"
                            initial={{height: 0}}
                            animate={{height: activeIndex.includes(index) ? 'auto' : 0}}
                            transition={{duration: 0.25, ease: 'easeIn'}}
                        >
                            <div className="pb-6 px-6 sm:[&_.gap-component]:mb-6 [&_*:last-child]:!mb-0">
                                {(accordion?.ac_data?.elements && accordion?.ac_data?.elements?.length > 0) &&
                                    childrenElements[index]
                                }
                            </div>
                        </motion.div>
                    </li>
                ))}
            </ul>
        </div>
    )
}
