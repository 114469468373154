'use client';

import React from 'react';
import {CommonFormWidgetProps, StringFieldSchema} from '@/app/components/form/types';

interface InstructionsWidgetProps extends CommonFormWidgetProps {
    field: StringFieldSchema
}

export default function InstructionsWidget({field}: InstructionsWidgetProps) {

    if(!field.options?.instructions) {
        return;
    }

    return (
        <div>
            {field.options.instructions}
        </div>
    );
}
