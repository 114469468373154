'use client';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import {InspirationListItem} from '@/types/objects/inspiration';
import {DocumentProperty, PimcoreLink} from '@/types/pimcore';
import InspirationTile from '@/app/components/inspirations/overview/InspirationTile';
import InspirationTeaserSliderButtons from '@/app/components/inspirations/teaser/InspirationTeaserSliderButtons';
import Link from 'next/link';
import useTranslations from '@/hooks/translations';

interface InspirationTeaserSliderProps {
    items: InspirationListItem[];
    link: PimcoreLink;
    type: 'inspiration' | 'press';
    overviewPageProperty: DocumentProperty | undefined;
}

const aspectRatioMap: { [key: number]: string } = {
    0: 'aspect-[3/4]',
    1: 'aspect-[3/2]',
    2: 'aspect-[1/1]',
}

export default function InspirationTeaserSlider({items, type, link, overviewPageProperty}: InspirationTeaserSliderProps) {
    const {tc} = useTranslations();

    return (
        <div className="w-full relative">
            <Swiper spaceBetween={16}
                    pagination={{
                        dynamicBullets: false,
                        clickable: true,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            loop: items?.length > 1,
                        },
                        768: {
                            slidesPerView: 2,
                            loop: items?.length > 2,
                        },
                        992: {
                            slidesPerView: 4,
                            loop: items?.length > 4,
                        },
                    }}
                    className="pb-8">
                {items.map((item, i) => (
                    <SwiperSlide key={item.id}>
                        <InspirationTile data={{
                             ...item,
                             renderClass: aspectRatioMap[i % 3],
                         }}/>
                    </SwiperSlide>
                ))}
                <div className="flex justify-between items-center mt-8">
                    <div>
                        <div
                            className={`${items?.length <= 4 ? 'md:hidden' : ''} ${items?.length <= 2 ? 'sm:hidden' : ''} ${items?.length <= 1 ? 'hidden' : ''}`}>
                            <InspirationTeaserSliderButtons/>
                        </div>
                    </div>
                    <div>
                        {link.path ? (
                            <Link href={link.path || '/'}
                                  className="btn btn-secondary"
                                  title={link.title || link.text}>
                                {link.text || link.title}
                            </Link>
                        ) : overviewPageProperty ? (
                            <Link href={overviewPageProperty.element?.fullPath || '/'}
                                  className="btn btn-secondary"
                                  title={tc(`app.${type}_teaser.view_all`)}>
                                {tc(`app.${type}_teaser.view_all`)}
                            </Link>
                        ) : <></>}
                    </div>
                </div>
            </Swiper>
        </div>
    )
}