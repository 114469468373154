import {store} from '@/redux/store';
import {getTranslations} from '@/api/api';
import {setLocale, setTranslations} from '@/redux/slices/translationsSlice';

export async function loadTranslations(locale?: string) {
    const translations: { [key: string]: string } = await getTranslations({
        catalog: 'messages'
    });

    store.dispatch(setLocale(locale ?? ''));
    store.dispatch(setTranslations(translations));
}

export function t(key: string): string {
    if (!store.getState().translations.translations) {
        return key;
    }

    const translations = store.getState()?.translations?.translations;

    return translations ? (translations[key] ? translations[key] : key) : key;
}
