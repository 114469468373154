'use client';

import React, {useEffect, useRef} from 'react';
import {FormState} from '@/app/components/form/types';
import useTranslations from '@/hooks/translations';

interface SuccessMessagesProps {
    state: FormState,
}

export default function ErrorMessages({state}: SuccessMessagesProps) {
    const messageDiv = useRef<HTMLDivElement>(null);
    const {tc} = useTranslations();

    useEffect(() => {

        if (!state.error) {
            return;
        }

        if (!messageDiv.current) {
            return;
        }

        messageDiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    }, [state]);

    return (
        <div ref={messageDiv} className="scroll-mt-[var(--header-height)]">
            {!!state.generalViolations.length && state.generalViolations.map((v, i) => (
                <div key={i} className="form-general-error">
                    <strong>{tc('form.error.general')}</strong>
                    <br/>
                    <code>{v.title}</code>
                </div>
            ))}
            {!!state.fieldViolations.length && (
                <div className="form-general-error">
                    <span>{tc('form.error.fields')}</span>
                </div>
            )}
        </div>
    )
}
