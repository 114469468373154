'use client';

import {useState} from 'react';
import {VideoContentElement} from '@/app/components/cms/content/Video/Video.types';
import PlayIcon from '@/images/icons/play.svg';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import Lightbox from '@/app/components/util/Lightbox/Lightbox';
import VideoInline from '@/app/components/cms/content/Video/VideoInline';

interface VideoLightboxProps {
    element: VideoContentElement
}

export default function VideoLightbox({element}: VideoLightboxProps) {
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);

    return (
        <>
            <div className="flex rounded-xl aspect-[16/9] overflow-hidden justify-center relative group">
                <img className="transition w-full object-cover group-hover:scale-105"
                     src={element.elementContext.data.video?.posterThumbnail?.path || ''}
                     alt="video thumb"
                />

                <div className="absolute inset-0 bg-opacity-0 transition group-hover:bg-opacity-20 bg-primary"></div>

                <button className="flex absolute inset-0 justify-center items-center w-full h-full"
                        onClick={() => setLightboxOpen(true)}>
                    <div
                        className="w-10 h-10 bg-white flex justify-center items-center bg-opacity-70 rounded-full transition-transform origin-center sm:w-20 sm:h-20 group-hover:scale-110 group-hover:bg-primary">
                        <PlayIcon className="absolute-center w-5 sm:w-10 h-auto text-primary-text transition group-hover:text-white"/>
                    </div>
                </button>
            </div>
            <ClientOnly>
                <Lightbox open={lightboxOpen} setOpen={setLightboxOpen}>
                    <div className="rounded-xl flex w-full h-full items-center justify-center">
                        <div className="container h-4/5 flex flex-col justify-center max-w-4xl">
                            <VideoInline element={element} autoplay={true}/>
                        </div>
                    </div>
                </Lightbox>
            </ClientOnly>
        </>
    )
}
