'use client';

import React from 'react';
import {CommonFormWidgetProps, StringFieldSchema} from '@/app/components/form/types';
import LabelWidget from '@/app/components/form/widgets/LabelWidget';
import {transformAttributes} from '@/app/components/form/util/helper';

interface TimeWidgetProps extends CommonFormWidgetProps {
    field: StringFieldSchema
}

export default function TimeWidget({name, field}: TimeWidgetProps) {
    return (
        <label>
            <LabelWidget field={field}/>
            <input
                className="form-input"
                type="time"
                name={name}
                id={name}
                defaultValue={field.default}
                {...transformAttributes(field.options.attr)}
            />
        </label>
    )
}
