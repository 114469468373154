'use client';

import React from 'react';
import {BooleanFieldSchema, CommonFormWidgetProps} from '@/app/components/form/types';
import {useFormStatus} from 'react-dom';
import {transformAttributes} from '@/app/components/form/util/helper';

interface SubmitWidgetProps extends CommonFormWidgetProps {
    field: BooleanFieldSchema
}

export default function SubmitWidget({field}: SubmitWidgetProps) {
    const {pending} = useFormStatus();


    return (
        <button
            disabled={pending}
            className="form-submit"
            type="submit"
            {...transformAttributes(field.options.attr)}
        >
            {field.title}
        </button>
    )
}
