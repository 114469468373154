export default function NewsListItemSkeleton() {
    return (
        <div className="relative flex h-full rounded-lg group u-border-gradient-portrait-grey animate-pulse">
            <div className="rounded-lg absolute inset-0 z-0 origin-left scale-x-0 bg-gradient-to-r to-transparent opacity-0 transition-all duration-300 from-grey-150 group-hover:scale-x-100 group-hover:opacity-100"></div>
            <div className="w-full z-10 flex flex-col items-start gap-6 p-6">
                <div className="aspect-2/1 w-full overflow-hidden rounded">
                    <div className="h-full w-full bg-grey-200"></div>
                </div>


                <div className="grow w-full">
                    <span className="block mb-4 h-2 w-1/4 bg-grey-200"></span>
                    <span className="block mb-0 h-6 w-full bg-grey-200"></span>
                    <span className="block mt-2 h-6 w-3/4 bg-grey-200"></span>
                    <span className="block mt-4 h-5 w-full bg-grey-200"></span>
                    <span className="block mt-2 h-5 w-3/4 bg-grey-200"></span>
                </div>

                <div className="h-12 w-2/3 shrink-0 rounded-full bg-grey-200">
                </div>
            </div>
        </div>
    )
}