'use client';

import React from 'react';
import {
    CommonFormWidgetProps, FieldSchema,
    MultipleChoiceFieldSchema,
    SimpleChoiceFieldSchema
} from '@/app/components/form/types';
import LabelWidget from '@/app/components/form/widgets/LabelWidget';
import {transformAttributes} from '@/app/components/form/util/helper';

interface ChoiceWidgetProps extends CommonFormWidgetProps {
    field: FieldSchema;
}

function isSimpleChoiceFieldSchema(field: any): field is SimpleChoiceFieldSchema {
    return field.type === 'string';
}

function isMultipleChoiceFieldSchema(field: any): field is MultipleChoiceFieldSchema {
    return field.type === 'array';
}

export default function ChoiceWidget(props: ChoiceWidgetProps) {
    const {field} = props;

    if (!isSimpleChoiceFieldSchema(field) && !isMultipleChoiceFieldSchema(field)) {
        return <></>;
    }

    if (field.options.choice.expanded && !field.options.choice.multiple) {
        return <ChoiceRadioGroupWidget {...{...props, field: field as SimpleChoiceFieldSchema}} />;
    }

    if (!field.options.choice.expanded && !field.options.choice.multiple) {
        return <ChoiceSelectWidget {...{...props, field: field as SimpleChoiceFieldSchema}}/>
    }

    if (field.options.choice.expanded && field.options.choice.multiple) {
        return <ChoiceCheckboxGroupWidget {...{...props, field: field as MultipleChoiceFieldSchema}} />
    }

    if (!field.options.choice.expanded && field.options.choice.multiple) {
        return <ChoiceMultiselectWidget {...{...props, field: field as MultipleChoiceFieldSchema}} />
    }
}

export function ChoiceRadioGroupWidget({name, field}: ChoiceWidgetProps) {
    if (!isSimpleChoiceFieldSchema(field)) {
        return <></>;
    }
    return (
        <div className="form-choice-expanded-group">
            <LabelWidget field={field}/>
            {field.enum.map((o, i) => (
                <div key={i} className="form-choice-expanded">
                    <label>
                        <input
                            className="form-radio"
                            type="radio"
                            name={name}
                            value={o}
                            defaultChecked={field.default === o}
                            {...transformAttributes(field.options.attr)}
                        />
                        {field.options.choice.enumTitles[i]}
                    </label>
                </div>
            ))}
        </div>
    )
}

export function ChoiceCheckboxGroupWidget({name, field}: ChoiceWidgetProps) {
    if (!isMultipleChoiceFieldSchema(field)) {
        return <></>;
    }
    return (
        <div className="form-choice-expanded-group">
            <LabelWidget field={field}/>
            {field.items.enum.map((o, i) => (
                <div key={i} className="form-choice-expanded">
                    <label>
                        <input
                            className="form-checkbox"
                            type="checkbox"
                            name={`${name}[]`}
                            value={o}
                            defaultChecked={field.default?.includes(o)}
                            {...transformAttributes(field.options.attr)}
                        />
                        <span className="form-choice-label">{field.options.choice.enumTitles[i]}</span>
                    </label>
                </div>
            ))}
        </div>
    )
}

export function ChoiceSelectWidget({name, field}: ChoiceWidgetProps) {
    if (!isSimpleChoiceFieldSchema(field)) {
        return <></>;
    }
    return (
        <label>
            <LabelWidget field={field}/>
            <select
                className="form-select"
                name={name}
                defaultValue={field.default ? field.default : field.options.choice.placeholder ? '' : undefined}
                {...transformAttributes(field.options.attr)}
            >
                {field.options.choice.placeholder && (
                    <option value="" disabled>{field.options.choice.placeholder}</option>
                )}
                {field.enum.map((o, i) => (
                    <option key={i} value={o} label={field.options.choice.enumTitles[i]}/>
                ))}
            </select>
        </label>
    )
}

export function ChoiceMultiselectWidget({name, field}: ChoiceWidgetProps) {
    if (!isMultipleChoiceFieldSchema(field)) {
        return <></>;
    }
    return (
        <label>
            <LabelWidget field={field}/>
            <select
                multiple
                className="form-multiselect !h-32"
                name={`${name}[]`}
                defaultValue={field.default}
                {...transformAttributes(field.options.attr)}
            >
                {field.items.enum.map((o, i) => (
                    <option key={i} value={o} label={field.options.choice.enumTitles[i]}/>
                ))}
            </select>
        </label>
    )
}
