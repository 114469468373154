'use client';

import {PimcoreVideoElement} from '@/types/pimcore';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import React from 'react';

interface VideoItemProps {
    element: PimcoreVideoElement;
    className?: string;
    playVideosInOverview?: boolean;
}

export default function GalleryVideoItem({element, className, playVideosInOverview}: VideoItemProps) {

    const video = element.videoThumbnails?.find(v => v.name === 'galleryVideo');

    if (!video || video.status !== 'finished' || !playVideosInOverview) {
        if(element.posterThumbnail) {
            return <PimcoreImage image={element.posterThumbnail} className={className}/>
        } else {
            return <></>
        }
    }

    return (
        <video
            className={className}
            muted
            autoPlay
            playsInline
            loop
            preload="none">
            <source src={video.formats.mp4} type="video/mp4"/>
        </video>
    )

}
