'use client';

import {SearchAdditionalFilterChip, SearchResponse} from '@/types/search';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import FilterOverlay from '@/app/components/search/filter/FilterOverlay';
import useSearch from '@/hooks/search';
import FilterChips from '@/app/components/search/filter/FilterChips';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import FilterHelper from '@/app/components/search/filter/FilterHelper';
import useTranslations from '@/hooks/translations';
import {NewsSearchItem} from '@/types/news';
import NoItemsFound from '@/app/components/search/NoItemsFound';
import NewsListItem from '@/app/components/news/list/NewsListItem';
import NewsListItemSkeleton from '@/app/components/news/list/NewsListItemSkeleton';
import NewsOverviewPublicationDateFilter from '@/app/components/news/overview/NewsOverviewPublicationDateFilter';
import {DateRange} from 'react-day-picker';
import {format, parse} from 'date-fns';

interface NewsOverviewListProps {
    limit: number;
    order: 'asc' | 'desc';
    orderKey: string;
    initialSearchResponse: SearchResponse<NewsSearchItem>;
    initialSearchQuery: string;
}

export default function NewsOverviewList({
                                             limit,
                                             orderKey,
                                             order,
                                             initialSearchResponse,
                                             initialSearchQuery,
                                         }: NewsOverviewListProps) {
    const [initialLoaded, setInitialLoaded] = useState<boolean>(false);
    const [date, setDate] = React.useState<DateRange | undefined>(undefined);
    const filterFormRef = useRef<HTMLFormElement | null>(null);
    const {tc} = useTranslations();

    const {
        items,
        loading,
        handlePagination,
        handleReset,
        handleFilter,
        filterOpen,
        setFilterOpen,
        chips,
        handleChipRemove,
        totalCount,
    } = useSearch<NewsSearchItem>(
        'newsSearch',
        initialSearchResponse.hits,
        initialSearchResponse.aggregations || [],
        filterFormRef,
        initialSearchResponse.totalCount || 0,
        limit,
        order,
        orderKey,
    );

    const additionalSearchQuery: string = useMemo(() => {
        let q = initialSearchQuery;

        if (date?.from) {
            q += `&filter[publicationDateFrom]=${format(date.from, 'yyyy-MM-dd')}`;
        }

        if (date?.to) {
            q += `&filter[publicationDateTo]=${format(date.to, 'yyyy-MM-dd')}`;
        }

        return q;
    }, [initialSearchQuery, date]);

    const additionalFilterChips: SearchAdditionalFilterChip[] = useMemo(() => {
        const additionalChips: SearchAdditionalFilterChip[] = [];

        if (date?.from) {
            const chip = {
                label: format(date.from, 'dd.MM.y'),
                key: 'publicationDate',
                value: format(date.from, 'y-MM-dd'),
                handleRemove: () => setDate(undefined),
            }


            if (date?.to) {
                chip.label += ` - ${format(date.to, 'dd.MM.y')}`;
            }

            additionalChips.push(chip);
        }

        return additionalChips;
    }, [date]);

    const onFilter = (closeFilter: boolean) => {
        handleFilter(closeFilter, additionalSearchQuery);
    }

    const onReset = () => {
        setDate(undefined);
        handleReset(additionalSearchQuery);
    }

    const handleInitialDateFilter = (searchParams: URLSearchParams) => {
        const dateFrom = searchParams.get('filter[publicationDateFrom]');
        const dateTo = searchParams.get('filter[publicationDateTo]');

        if (dateTo || dateFrom) {
            const tempDate: DateRange = {
                from: dateFrom ? parse(dateFrom, 'yyyy-MM-dd', new Date()) : undefined,
                to: dateTo ? parse(dateTo, 'yyyy-MM-dd', new Date()) : undefined,
            };

            setDate(tempDate);
        }
    }

    useEffect(() => {
        if (initialLoaded) {
            handleFilter(false, additionalSearchQuery);
        } else {
            setInitialLoaded(true);
        }
    }, [additionalSearchQuery]);

    return (
        <div className="toolbox-element toolbox-news-overview">
            <ClientOnly>
                <FilterHelper formRef={filterFormRef}
                              handleFilter={() => onFilter(false)}
                              additionalFilterHelperFn={handleInitialDateFilter}/>
            </ClientOnly>
            <div className="container mt-12">
                <div
                    className={`grid grid-cols-1 ${initialSearchResponse.aggregations?.length ? 'gap-12 lg:grid-cols-4' : ''}`}>
                    {initialSearchResponse.aggregations?.length ? (
                        <form noValidate
                              ref={filterFormRef}
                              onChange={() => onFilter(false)}
                              onSubmit={(e) => e.preventDefault()}>
                            <FilterOverlay handleFilter={(closeFilter: boolean) => onFilter(closeFilter)}
                                           filterOpen={filterOpen}
                                           setFilterOpen={setFilterOpen}
                                           searchFilterChips={[...chips, ...additionalFilterChips]}
                                           additionalFilterItems={
                                               <NewsOverviewPublicationDateFilter date={date}
                                                                                  setDate={setDate}/>
                                           }
                                           buttonText={'app.search.news_search.open_filter'}
                                           title={'app.search.news_search.filter_title'}
                                           aggregations={initialSearchResponse.aggregations || []}
                                           handleReset={onReset}/>
                        </form>
                    ) : <></>}
                    <div className="lg:col-span-3">
                        <FilterChips chips={chips}
                                     additionalChips={additionalFilterChips}
                                     removeChip={handleChipRemove}
                                     loading={loading}
                                     additionalSearchQuery={additionalSearchQuery}/>
                        {loading ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4">
                                {[...Array(limit)].map((_, index) => (
                                    <NewsListItemSkeleton key={index}/>
                                ))}
                            </div>
                        ) : totalCount === 0 ? (
                            <NoItemsFound title={'app.news.search.no_news_found'}/>
                        ) : (
                            <div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4">
                                    {items.map((item) => (
                                        <NewsListItem key={item.id} data={item}/>
                                    ))}
                                </div>
                                {totalCount > items.length ? (
                                    <div className="justify-center flex mt-6">
                                        <button className="btn underline text-primary-text"
                                                type="button"
                                                onClick={() => handlePagination(additionalSearchQuery)}
                                                disabled={loading}>
                                            {tc('app.search.load_more')}
                                        </button>
                                    </div>
                                ) : <></>}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}