import useTranslations from '@/hooks/translations';
import {ReactNode, useEffect} from 'react';
import {SearchAdditionalFilterChip, SearchAggregation, SearchFilterChip} from '@/types/search';
import CloseIcon from '@/images/icons/close.svg';
import ResetIcon from '@/images/icons/reset.svg';
import FilterIcon from '@/images/icons/filter.svg';
import FilterItem from '@/app/components/search/filter/FilterItem';

interface FilterOverlayProps {
    title: string;
    buttonText: string;
    aggregations: SearchAggregation[];
    searchFilterChips: Array<SearchAdditionalFilterChip | SearchFilterChip>;
    handleFilter: (closeFilter: boolean) => void;
    handleReset: (query: string) => void;
    filterOpen: boolean;
    setFilterOpen: (open: boolean) => void;
    children?: ReactNode;
    additionalFilterItems?: ReactNode;
    hideReset?: boolean;
    additionalSearchQuery?: string;
}

export default function FilterOverlay({
                                          aggregations,
                                          title,
                                          buttonText,
                                          children,
                                          handleFilter,
                                          filterOpen,
                                          setFilterOpen,
                                          handleReset,
                                          searchFilterChips,
                                          additionalFilterItems,
                                          hideReset = true,
                                          additionalSearchQuery
                                      }: FilterOverlayProps) {
    const {tc} = useTranslations();

    useEffect(() => {
        document.body.style.overflow = filterOpen ? 'hidden' : '';
    }, [filterOpen]);

    return (
        <div>
            <div className="lg:hidden">
                <button className="btn btn-primary w-full text-center flex gap-3 items-center justify-center"
                        onClick={() => setFilterOpen(true)}>
                    {searchFilterChips.length > 0 && (
                        <span className="shrink-0 flex h-4 w-4 items-center justify-center rounded-full bg-white text-primary-text text-[11px]">
                            {searchFilterChips.length}
                        </span>
                    )}
                    <span className="truncate">
                        {tc(buttonText)}
                    </span>
                    <FilterIcon className="shrink-0 h-6 w-6"/>
                </button>
            </div>

            <div
                className={`fixed flex flex-col justify-between inset-0 bg-white lg:static z-50 ${filterOpen ? 'block' : 'hidden lg:block'}`}>
                <div className="px-6 sm:px-10 pb-8 flex justify-between items-center gap-4 lg:hidden py-6 shrink-0">
                    <div className="h3 mb-0">
                        {tc(title)}
                    </div>
                    <button onClick={() => setFilterOpen(false)}
                            className="w-12 h-12 rounded-full bg-grey-125 flex justify-center items-center hover:bg-grey-150 transition-all">
                        <CloseIcon className="w-6 h-6 text-primary-text"/>
                    </button>
                </div>
                <div className="flex-1 lg:flex-auto overflow-y-auto pb-6">
                    {!hideReset || searchFilterChips.length > 0 ? (
                        <div className="hidden lg:block mb-6">
                            <button className="flex items-center gap-2 text-sm font-bold text-primary-text disabled:text-grey-300"
                                    disabled={searchFilterChips.length === 0}
                                    type={'reset'}
                                    onClick={() => handleReset(additionalSearchQuery || '')}>
                                {tc('app.search.filter.reset_filter')}
                                <ResetIcon className="w-6 h-6"/>
                            </button>
                        </div>
                    ) : <></>}
                    <div className="px-6 sm:px-10 lg:hidden mb-8">
                        {children ? children : <></>}
                    </div>
                    <div className="grid grid-cols-1 gap-4 px-6 sm:px-10 lg:gap-6 lg:px-0">
                        {aggregations.map((aggregation, i) => (
                            <FilterItem key={aggregation.key || i}
                                        handleFilterChange={() => handleFilter(false)}
                                        initialOpen={i === 0}
                                        aggregation={aggregation}/>
                        ))}
                        {additionalFilterItems ? additionalFilterItems : <></>}
                    </div>
                </div>
                <div
                    className="lg:hidden py-3 flex justify-end flex-wrap items-center px-6 sm:px-10 gap-4 border-t border-t-grey-200">
                    {!hideReset || searchFilterChips.length > 0 ? (
                        <button className="btn text-primary-text"
                                disabled={searchFilterChips.length === 0}
                                type={'reset'}
                                onClick={() => handleReset(additionalSearchQuery || '')}>
                            {tc('app.search.filter.reset')}
                        </button>
                    ) : <></>}
                    <button className="btn btn-primary" onClick={() => handleFilter(true)}>
                        {tc('app.search.filter.apply')}
                    </button>
                </div>
            </div>
        </div>
    )
}