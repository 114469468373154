import {enUS, de, cs, pl, Locale} from 'date-fns/locale';

export const getDatePickerLocale = (locale: 'de' | 'en' | 'cs' | 'pl'): Locale => {
    switch (locale) {
        case 'de':
            return de;
        case 'en':
            return enUS;
        case 'pl':
            return pl;
        case 'cs':
            return cs;
        default:
            return enUS;
    }
}