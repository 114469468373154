'use client';

import InspirationTile from '@/app/components/inspirations/overview/InspirationTile';
import { useEffect, useState } from 'react';
import { shuffle } from '@/util/helper/general';
import { InspirationListItem } from '@/types/objects/inspiration';

const _randomTilesClasses = [
    ['aspect-3/4', 'aspect-3/2', 'aspect-square', 'aspect-3/4', 'aspect-3/2'],
    ['aspect-square', 'aspect-3/4', 'aspect-square', 'aspect-3/2', 'aspect-square']
];

interface TilesRandomizerProps {
    items: InspirationListItem[];
    loading: boolean;
}

export default function TilesRandomizer({ items, loading }: TilesRandomizerProps) {
    const [ isRandomized, setRandomized ] = useState<boolean>(false);

    const [ renderClassesLeft, setRenderClassesLeft ] = useState<string[]>(shuffle(_randomTilesClasses[0]));
    const [ renderClassesRight, setRenderClassesRight ] = useState<string[]>(shuffle(_randomTilesClasses[1]));

    useEffect(() => {
        setRandomized(false);

        if (!items.length) return;

        const tilesLeft = document.querySelectorAll('.inspiration-tiles-left .inspiration-tile:not(.is-randomized)');
        const tilesRight = document.querySelectorAll('.inspiration-tiles-right .inspiration-tile:not(.is-randomized)');

        renderClassesLeft.forEach((renderClass: string, index: number) => {
            if (tilesLeft.length > index) {
                tilesLeft[index].classList.remove('aspect-square');
                tilesLeft[index].classList.add(renderClass, 'is-randomized');
            }
        });

        renderClassesRight.forEach((renderClass: string, index: number) => {
            if (tilesRight.length > index) {
                tilesRight[index].classList.remove('aspect-square');
                tilesRight[index].classList.add(renderClass, 'is-randomized');
            }
        });

        setRandomized(true);
    }, [items]);

    return (
        <div className="relative">
            <div className={`absolute inset-x-0 top-0 flex flex-col sm:flex-row w-full gap-4 transition-opacity ${loading || !isRandomized ? 'visible opacity-100' : 'invisible opacity-0 h-0'}`}>
                <div className="flex w-full flex-col gap-4">
                    {renderClassesLeft.map((className, index) => (
                        <div key={index} className={`bg-grey-100 w-full rounded-lg ${className}`} />
                    ))}
                </div>

                <div className="flex w-full flex-col gap-4">
                    {renderClassesRight.map((className, index) => (
                        <div key={index} className={`bg-grey-100 w-full rounded-lg ${className}`} />
                    ))}
                </div>

                {loading && (
                    <div className="pointer-events-none absolute inset-0 z-10 bg-white bg-opacity-50" />
                )}
            </div>

            <div className={`flex w-full flex-col sm:flex-row gap-4 transition-opacity ${isRandomized ? 'visible opacity-100' : 'invisible opacity-0'}`}>
                <div className="flex w-full flex-col gap-4 inspiration-tiles-left">
                    {items.filter((e, index) => index % 2 === 0).map(item => (
                        <InspirationTile data={item} key={item.id} />
                    ))}
                </div>

                <div className="flex w-full flex-col gap-4 inspiration-tiles-right">
                    {items.filter((e, index) => index % 2 === 1).map(item => (
                        <InspirationTile data={item} key={item.id} />
                    ))}
                </div>
            </div>
        </div>
    )
}