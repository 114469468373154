'use client';

import React from 'react';
import {FieldSchema, FormViolations} from '@/app/components/form/types';
import {findFieldViolation} from '@/app/components/form/util/helper';
import Alert from '@/images/icons/alert.svg';


interface WidgetWrapperProps {
    fieldName: string,
    field: FieldSchema,
    fieldViolations: FormViolations[],
    children: React.ReactNode
}

export default function FormWidgetWrapper({fieldName, field, fieldViolations, children}: WidgetWrapperProps) {
    const [hasError, errorMessage] = findFieldViolation(fieldViolations, fieldName);

    return (
        <div className={hasError ? 'form-invalid' : ''}>

            {children}

            {field.options.helpText && (
                <div className="form-help-text">{field.options.helpText}</div>
            )}

            {errorMessage && (
                <div className="form-invalid-message flex gap-3 items-center">
                    <Alert className="w-6 h-auto text-red-200"/>
                    {errorMessage}
                </div>
            )}

        </div>
    )
}
