'use client';

import React from 'react';
import {CommonFormWidgetProps, StringFieldSchema} from '@/app/components/form/types';
import {transformAttributes} from '@/app/components/form/util/helper';

interface HiddenWidgetProps extends CommonFormWidgetProps {
    field: StringFieldSchema
}

export default function HiddenWidget({name, field}: HiddenWidgetProps) {
    if(name === 'formRuntimeData') {
        return;
    }

    return (
        <input
            type="hidden"
            name={name}
            defaultValue={field.default}
            {...transformAttributes(field.options.attr)}
        />
    )
}
