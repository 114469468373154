'use client';

import React from 'react';
import {CommonFormWidgetProps, StringFieldSchema} from '@/app/components/form/types';

interface HoneypotWidgetProps extends CommonFormWidgetProps {
    field: StringFieldSchema
}

export default function HoneypotWidget({name, field}: HoneypotWidgetProps) {
    return (
        <input className="form-input form-input-hp" type={field.type} name={name} autoComplete="off" role="presentation" tabIndex={-1} />
    )
}
