'use client';

import React from 'react';
import {CommonFormWidgetProps, StringFieldSchema} from '@/app/components/form/types';
import IframeContent from '@/app/components/util/IframeContent/IframeContent';

interface SnippetWidgetProps extends CommonFormWidgetProps {
    field: StringFieldSchema
}

export default function SnippetWidget({field}: SnippetWidgetProps) {
    const snippetId = field.options.snippetId ?? null;

    if(!snippetId) {
        return <></>
    }

    return (
        <IframeContent documentId={snippetId}/>
    )
}
