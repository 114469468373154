'use client';

import Link from 'next/link';
import {slugify} from '@/util/helper/string';
import {NewsSearchItem} from '@/types/news';
import {format} from 'date-fns';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import useTranslations from '@/hooks/translations';

interface NewsListItemProps {
    data: NewsSearchItem;
}

export default function NewsListItem({data}: NewsListItemProps) {
    const {tc} = useTranslations();
    return (
        <Link href={slugify(data.detailUrl)}
              className="relative block rounded-lg u-border-gradient-landscape group">
            <div
                className="absolute inset-0 z-0 origin-left scale-x-0 rounded-lg bg-gradient-to-r to-transparent opacity-0 transition-all duration-300 from-grey-150 group-hover:scale-x-100 group-hover:opacity-100"></div>
            <div className="relative z-10 flex flex-col justify-between gap-4 px-6 py-7 h-full">
                <div className="flex flex-col gap-4">
                    {data.teaserImage ? (
                        <div className="aspect-[16/9] w-full overflow-hidden relative">
                            <PimcoreImage image={data.teaserImage}
                                          className="absolute inset-0 w-full h-full object-cover object-center"/>
                        </div>
                    ) : <></>}
                    <div className="meta text">
                        {format((parseInt(data.publicationDate) * 1000), 'dd.MM.yyyy')}
                    </div>
                    <div className="mb-0 text-blue-200 h5 line-clamp-2 hyphens-auto">
                        {data.title}
                    </div>
                    <div className="text-grey-500 line-clamp-3 hyphens-auto wysiwyg mb-0" dangerouslySetInnerHTML={{
                        __html: data.teaserText,
                    }}/>
                </div>

                <div
                    className="h-12 flex items-center text-sm px-6 font-bold w-fit text-primary-text rounded-full bg-grey-150 transition-all group-hover:bg-grey-175">
                    {tc('app.news.search_listing.read_more')}
                </div>
            </div>

            {data.exclusive && (
                <span className="absolute -top-3 rounded text-secondary-text left-5.5 u-tag bg-secondary-100">
                    {tc('news.exclusive')}
                </span>
            )}
        </Link>
    )
}
