import Danger from '@/images/icons/alert.svg';
import ThumbsUp from '@/images/icons/thumbs-up.svg';
import React from 'react';

interface AlertProps {
    title?: string;
    text?: string;
    type: 'error' | 'success';
    additionalText?: React.ReactNode;
}

export default function Alert({title, text, type, additionalText}: AlertProps) {
    let containerClassNames: string = '';
    let iconContainerClassNames: string = '';
    let icon: React.ReactNode | null = null;

    switch (type) {
        case 'error':
            containerClassNames = 'bg-white border-red-200 text-red-700';
            iconContainerClassNames = 'bg-red-200 text-white';
            icon = <Danger className="w-6 h-6"/>
            break;
        case 'success':
            containerClassNames = 'bg-white border-green-500';
            iconContainerClassNames = 'bg-green-500 text-white';
            icon = <ThumbsUp  className="w-6 h-6"/>
            break;
    }

    return (
        <div className={`border-2 rounded-lg flex mb-4 ${containerClassNames}`}>
            <div className={`p-2 shrink-0 ${iconContainerClassNames}`}>
                {icon ? icon : <></>}
            </div>
            <div className="py-2.5 px-4 grid gap-1">
                {title ? (
                    <p className="font-bold text-blue-300 mb-0">{title}</p>
                ) : <></>}
                {text ? (
                    <p className="small text-grey-500 mb-0">
                        {text}
                        {additionalText ? additionalText : <></>}
                    </p>
                ) : <></>}
            </div>
        </div>
    )
}