import Link from 'next/link';
import {NewsEntry, NewsTeaserEntry} from '@/types/objects/news';
import { Inspiration } from '@/types/objects/inspiration';
import IconArrowRight from '@/images/icons/arrow-right.svg';
import { slugify } from '@/util/helper/string';
import {t} from '@/util/translations/getTranslations';
import {NewsSearchItem} from '@/types/news';

interface RelatedNewsItemProps {
    data: NewsEntry | Inspiration | NewsTeaserEntry | NewsSearchItem;
}

export default function RelatedNewsItem({ data }: RelatedNewsItemProps) {

    return (
        <Link href={slugify(data.detailUrl)}
              className="relative block rounded-lg u-border-gradient-landscape group">

            <div className="absolute inset-0 z-0 origin-left scale-x-0 rounded-lg bg-gradient-to-r to-transparent opacity-0 transition-all duration-300 from-grey-150 group-hover:scale-x-100 group-hover:opacity-100"></div>
            <div className="relative z-10 flex items-center justify-between gap-3 sm:gap-6 px-6 py-7">
                <div className="flex grow flex-col gap-4">
                    <span className="mb-0 text-blue-200 h5 hyphens-auto md:line-clamp-2">
                        {data.title}
                    </span>
                    {data.teaserText ? (
                        <div className="text-grey-500 line-clamp-2 wysiwyg" dangerouslySetInnerHTML={{
                            __html: data.teaserText
                        }}/>
                    ) : <></>}
                </div>
                <div className="h-12 w-12 min-w-12 rounded-full transition-colors u-center bg-grey-100 text-primary-text group-hover:bg-grey-175">
                    <IconArrowRight className="text-primary-text w-6 h-auto" />
                </div>
            </div>

            {data.exclusive && (
                <span className="absolute -top-3 rounded text-secondary-text left-5.5 u-tag bg-secondary-100">
                    {t('news.exclusive')}
                </span>
            )}
        </Link>
    )
}
