import React from 'react';
import {PimcoreImage as PimcoreImageInterface} from '@/types/pimcore';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import LineFooter from '@/../public/static-images/line-footer.svg';
import LineRectangle from '@/../public/static-images/line-rectangle.svg';
import LineRhombus from '@/../public/static-images/line-rhombus.svg';
import LineRfid from '@/../public/static-images/line-rfid.svg';
import LineRfidFooter from '@/../public/static-images/line-rfid-footer.svg';
import LineRfidHero from '@/../public/static-images/line-rfid-hero.svg';


interface ShapeTeaserProps {
    element: ShapeTeaserElement | null;
    shapeType: ShapeType;
}

export interface ShapeTeaserElement {
    type: ShapeContentType;
    image: PimcoreImageInterface | null;
    video: {
        videoPath: string;
        videoMimeType: string;
    } | null
}

export enum ShapeType {
    footer = 'footer',
    rectangle = 'rectangle',
    rhombus = 'rhombus',
    rfid = 'rfid',
    rfidFooter = 'rfidFooter',
    rfidHero = 'rfidHero'
}

export enum ShapeContentType {
    image = 'image',
    video = 'video',
    flat = 'flat'
}

export default function ShapeTeaser({element, shapeType}: ShapeTeaserProps) {

    const maskMap = {
        [ShapeType.footer]: 'image-mask-footer',
        [ShapeType.rectangle]: 'image-mask-rectangle',
        [ShapeType.rhombus]: 'image-mask-rhombus',
        [ShapeType.rfid]: 'image-mask-rfid scale-90 origin-center',
        [ShapeType.rfidFooter]: 'image-mask-rfid-footer scale-[93%] origin-top',
        [ShapeType.rfidHero]: 'image-mask-rfid-hero scale-[94%] origin-bottom',
    }

    const renderLine = () => {
        switch (shapeType) {
            case ShapeType.footer:
                return (
                    <div className="mr-5 lg:mr-11">
                        <LineFooter className="relative z-1 w-full h-auto translate-x-5 lg:translate-x-11"/>
                    </div>
                );
            case ShapeType.rectangle:
                return (
                    <div className="mr-5 lg:mr-11 mt-1.5 lg:mt-3">
                        <LineRectangle
                            className="relative z-1 w-full h-auto translate-x-5 lg:translate-x-11 -translate-y-1.5 lg:-translate-y-3"/>
                    </div>
                );
            case ShapeType.rhombus:
                return (
                    <div className="mr-5 lg:mr-11">
                        <LineRhombus className="relative z-1 w-full h-auto translate-x-5 lg:translate-x-11"/>
                    </div>
                );
            case ShapeType.rfid:
                return (
                    <div>
                        <LineRfid className="relative z-1 w-full h-auto translate-x-[-0.25%]"/>
                    </div>
                );
            case ShapeType.rfidFooter:
                return (
                    <div>
                        <LineRfidFooter className="relative z-1 w-full h-auto translate-x-[-2%]"/>
                    </div>
                );
            case ShapeType.rfidHero:
                return (
                    <div>
                        <LineRfidHero className="relative z-1 w-full h-auto translate-x-[-2.5%]"/>
                    </div>
                );
            default:
                return null;
        }
    }

    if (!element) {
        return;
    }

    return (
        <div className="relative">
            <div className={`${maskMap[shapeType]} absolute left-0 top-0 w-full h-full z-0`}>
                {element.type === ShapeContentType.video && element.video && (
                    <video className="w-full h-full object-cover" muted autoPlay playsInline loop preload="none">
                        <source src={element.video.videoPath} type={element.video.videoMimeType}/>
                    </video>
                )}
                {element.type === ShapeContentType.image && element.image && (
                    <PimcoreImage image={element.image}
                                  withLightbox={false}
                                  className="w-full h-full object-cover"/>
                )}
                {element.type === ShapeContentType.flat && (
                    <div className="w-full h-full bg-gradient-to-r from-blue-200 to-blue-125"/>
                )}
            </div>
            {renderLine()}
        </div>
    )
}
