'use client';

import { useState } from 'react';
import {ImageContentElement} from '@/app/components/cms/content/Image/Image.types';
import Link from 'next/link';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import Lightbox from '@/app/components/util/Lightbox/Lightbox';

interface ImageProps {
    element: ImageContentElement
}

export default function Image({element}: ImageProps) {
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);

    return (
        <div className="toolbox-element toolbox-image">
            <>
                {element.elementContext.data.image.image?.path ? (
                    <>
                        {element?.elementContext?.data?.image_link?.path ?
                            <Link href={element.elementContext.data.image_link.path}
                                  title={element?.elementContext?.data?.image_link.text}
                                  target={element?.elementContext?.data?.image_link.target}>
                                <PimcoreImage
                                    image={{
                                        ...element.elementContext.data.image.image,
                                        description: element.elementContext.data.image.caption || element.elementContext.data.image.image.title,
                                    }}
                                    withLightbox={false}
                                    withCaption={element.elementContext.data.show_caption}
                                    className="w-full h-auto rounded-lg"
                                />
                            </Link> :
                            <>
                                {element.elementContext.data.use_light_box ?
                                    <>
                                        <div onClick={() => setLightboxOpen(true)}>
                                            <PimcoreImage
                                                image={{
                                                    ...element.elementContext.data.image.image,
                                                    description: element.elementContext.data.image.caption || element.elementContext.data.image.image.title,
                                                }}
                                                withLightbox={element.elementContext.data.use_light_box}
                                                withCaption={element.elementContext.data.show_caption}
                                                className="w-full h-auto rounded-lg"
                                            />
                                        </div>
                                        <ClientOnly>
                                            <Lightbox open={lightboxOpen} setOpen={setLightboxOpen}>
                                                <div className="flex w-full h-full items-center justify-center">
                                                    <div className="container h-4/5 flex flex-col justify-center max-w-4xl">
                                                        <PimcoreImage
                                                            image={{
                                                                ...element.elementContext.data.image.lightbox,
                                                                description: element.elementContext.data.image.caption || element.elementContext.data.image.lightbox.title,
                                                            }}
                                                            withLightbox={false}
                                                            withCaption={element.elementContext.data.show_caption}
                                                            invertedCaption
                                                            className="w-full h-auto rounded-lg max-h-auto"
                                                        />
                                                    </div>
                                                </div>
                                            </Lightbox>
                                        </ClientOnly>
                                    </> :
                                    <PimcoreImage
                                        image={{
                                            ...element.elementContext.data.image.image,
                                            description: element.elementContext.data.image.caption || element.elementContext.data.image.image.title,
                                        }}
                                        withLightbox={element.elementContext.data.use_light_box}
                                        withCaption={element.elementContext.data.show_caption}
                                        className="w-full h-auto rounded-lg"
                                    />
                                }
                            </>
                        }
                    </>
                ) : <></>}
            </>
        </div>
    )
}
