'use client';

import React from 'react';
import {ArrayFieldSchema, CommonContainerWidgetProps, CommonFormWidgetProps} from '@/app/components/form/types';
import {renderFormLayout} from '@/app/components/form/util/renderer';

interface FieldsetWidgetProps extends CommonContainerWidgetProps {
    field: ArrayFieldSchema
}

export default function FieldsetWidget({name, field, formSchema, state}: FieldsetWidgetProps) {
    return (
        <div className="form-container">
            <h5 className="mb-2">{field.title}</h5>
            {renderFormLayout(formSchema, field.items.properties, state, `${name}.0.`)}
        </div>
    )
}
