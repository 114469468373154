'use client';

import {SearchResponse} from '@/types/search';
import {InspirationListItem} from '@/types/objects/inspiration';
import {useRef} from 'react';
import useSearch from '@/hooks/search';
import FilterChips from '@/app/components/search/filter/FilterChips';
import FilterOverlay from '@/app/components/search/filter/FilterOverlay';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import TilesRandomizer from '@/app/components/inspirations/overview/TilesRandomizer';
import useTranslations from '@/hooks/translations';

interface InspirationOverviewListProps {
    initialSearchResponse: SearchResponse<InspirationListItem>;
    initialSearchQuery: string;
    limit: number;
}

export default function InspirationOverviewList({initialSearchResponse, initialSearchQuery, limit}: InspirationOverviewListProps) {
    const formRef = useRef<HTMLFormElement | null>(null);
    const {tc} = useTranslations();

    const {
        items,
        loading,
        handlePagination,
        handleReset,
        handleFilter,
        filterOpen,
        setFilterOpen,
        chips,
        handleChipRemove,
        totalCount,
    } = useSearch<InspirationListItem>(
        'inspirationSearch',
        initialSearchResponse.hits,
        initialSearchResponse.aggregations || [],
        formRef,
        initialSearchResponse.totalCount || 0,
        limit,
        'asc',
        '',
    );

    const onFilter = (closeFilter: boolean) => {
        handleFilter(closeFilter, initialSearchQuery);
    }

    const onReset = () => {
        handleReset(initialSearchQuery);
    }

    return (
        <div className="toolbox-element mb-12 toolbox-inspiration-overview">
            <div className="flex flex-col gap-8 lg:gap-12 lg:flex-row pb-24 relative">
                <section className="w-full flex-col gap-6 lg:flex lg:w-1/4">
                    {(initialSearchResponse.aggregations || [])?.length > 0 && (
                        <form noValidate
                              ref={formRef}
                              onChange={() => onFilter(false)}
                              onSubmit={(e) => e.preventDefault()}>

                            <FilterOverlay
                                handleFilter={(closeFilter: boolean) => onFilter(closeFilter)}
                                filterOpen={filterOpen}
                                setFilterOpen={setFilterOpen}
                                searchFilterChips={chips}
                                buttonText="app.search.inspirations.open_filter"
                                title="app.search.inspirations.filter_title"
                                aggregations={initialSearchResponse.aggregations || []}
                                handleReset={onReset}
                                hideReset={false}
                            />
                        </form>
                    )}
                </section>

                <section className="w-full lg:w-3/4 flex flex-col">
                    <div className="lg:h-12 w-full">
                        <FilterChips
                            chips={chips}
                            removeChip={(chip) => handleChipRemove(chip, initialSearchQuery)}
                            loading={loading}
                        />
                    </div>

                    <ClientOnly>
                        <TilesRandomizer
                            items={items}
                            loading={loading}
                        />
                    </ClientOnly>

                    {totalCount > items.length && (
                        <div className="justify-center flex mt-6">
                            <button className="btn underline text-primary-text"
                                    type="button"
                                    onClick={() => handlePagination(initialSearchQuery)}
                                    disabled={loading}>
                                {tc('app.search.load_more')}
                            </button>
                        </div>
                    )}
                </section>
            </div>
        </div>
    )
}