import {expo} from 'maath/easing';

export const headerNames = {
    baseUrl: 'X-Base-Url',
    pathname: 'X-Pathname',
    urlSearchParams: 'X-URL-Search-Params',
    statusCode: 'X-Status-Code',
    documentId: 'X-Document-Id',
    objectId: 'X-Object-Id',
    className: 'X-Class-Name',
    locale: 'X-Locale',
    pimcorePreview: 'X-Pimcore-Preview',
    sessionId: 'X-Pimcore-Session-Id',
    siteId: 'X-Site-Id',
    rootDocumentId: 'X-Root-Document-Id',
    authenticated: 'X-Authenticated',
    profile: 'X-Profile',
    i18nContext: 'X-I18n-Context',
    seoContext: 'X-Seo-Context',
    breadcrumbItems: 'X-Breadcrumb-Items',
    theme: 'X-Theme',
    forwardedHost: 'X-Forwarded-Host',
    /* FormBuilder Available Headers */
    formBuilderDispatchLocation: 'X-FormBuilder-Dispatch-Location',
    formBuilderDoubleOptInSession: 'X-FormBuilder-Double-Opt-In-Session'
}

export const locales = ['de', 'en', 'pl', 'cs'];
export const defaultLocale = 'de';

export const themeRathgeber = 'rathgeber';
export const themeSmartTec = 'smart-tec';
export const availableThemes = [themeRathgeber, themeSmartTec]
