import {useState, FormEvent, useEffect, useRef} from 'react';
import FormError from '@/app/components/form/FormError';
import Check from '@/images/icons/check.svg';

interface CheckboxProps {
    name: string;
    label: string;
    required?: boolean;
    value?: string | number;
    error?: string;
    onChange?: (checked: boolean) => void;
    centeredLabel?: boolean;
}

export default function CheckboxField({name, label, required = false, error, value, onChange, centeredLabel = false}: CheckboxProps) {
    const [valid, setValid] = useState<boolean>(!error);
    const checkboxRef = useRef<HTMLInputElement>(null);

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.checked;

        setValid(!required || val);

        if (!onChange) return;

        onChange(val);
    };

    useEffect(() => {
        setValid(!error);
    }, [error])

    return (
        <div className="w-full flex flex-col gap-2">
            <label className={`form-checkboxfield ${!valid ? 'invalid' : ''}`}>
                <div className="self-start relative">
                    <input type="checkbox"
                           name={name}
                           ref={checkboxRef}
                           value={value || ''}
                           checked={!!checkboxRef?.current?.checked}
                           required={required}
                           onChange={handleChange}
                    />
                    <div className="focus-ring"></div>
                    <div className="icon">
                        <Check className="w-4 h-auto"/>
                    </div>
                </div>
                <span className={`label ${centeredLabel ? 'self-center' : ''}`} dangerouslySetInnerHTML={{
                    __html: label,
                }}/>
            </label>

            {error && <FormError error={error}/>}
        </div>
    )
}
