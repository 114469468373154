'use client';

import React from 'react';
import {useFormState} from 'react-dom';
import {
    FormInterface, FormState,
} from '@/app/components/form/types';
import {handleFormbuilderFormSubmit} from '@/app/components/form/actions';
import {renderFormLayout} from '@/app/components/form/util/renderer';
import SuccessMessages from '@/app/components/form/widgets/message/SuccessMessages';
import ErrorMessages from '@/app/components/form/widgets/message/ErrorMessages';

interface FormRendererProps {
    formInterface: FormInterface;
    formId: number;
    outputWorkflowId: number;
}

export default function FormRenderer({formInterface, formId, outputWorkflowId}: FormRendererProps) {
    const initialFormState: FormState = {
        formId: formId,
        outputWorkflowId: outputWorkflowId,
        generalViolations: [],
        fieldViolations: [],
        error: false,
        success: false,
        successResponse: null,
    }

    const [state, formAction ] = useFormState(handleFormbuilderFormSubmit, initialFormState);

    return (
        <>
            <SuccessMessages state={state}/>
            <ErrorMessages state={state}/>

            {(!state.success || state.error) && (
                <form action={formAction}>
                    {renderFormLayout(formInterface.schema, formInterface.schema.properties, state)}
                </form>
            )}
        </>
    )
}
