'use client';

import React from 'react';
import {CommonContainerWidgetProps, ObjectFieldSchema} from '@/app/components/form/types';
import {renderFormWidgets} from '@/app/components/form/util/renderer';
import LabelWidget from '@/app/components/form/widgets/LabelWidget';

interface ObjectWidgetProps extends CommonContainerWidgetProps {
    field: ObjectFieldSchema
}

export default function ObjectWidget({name, field, formSchema, state}: ObjectWidgetProps) {
    return (
        <label>
            <LabelWidget field={field}/>
            <div className="grid grid-cols-3 gap-4">
                {Object.entries(field.properties).map(([type, field], index) => (
                    <div className={field.options.layout === 'form-template-full' ? 'col-span-3' : 'col-span-3 md:col-span-1'} key={index}>
                        {renderFormWidgets(formSchema, `${name}.${type}`, field, state)}
                    </div>
                ))}
            </div>
        </label>
    )
}
