import useTranslations from '@/hooks/translations';
import AlertIcon from '@/images/icons/alert.svg';

interface NoItemsFoundProps {
    title: string;
}

export default function NoItemsFound({title}: NoItemsFoundProps) {
    const {tc} = useTranslations();

    return (
        <div className="border border-grey-200 rounded-lg flex overflow-hidden">
            <div className="w-10 bg-primary flex justify-center p-2">
                <AlertIcon className="text-white w-6 h-6" />
            </div>
            <div className="flex-1 bg-grey-100 py-2.5 px-4 text-grey-500 text-sm font-bold">
                {tc(title)}
            </div>
        </div>
    )
}