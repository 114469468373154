import {SearchAdditionalFilterChip, SearchFilterChip} from '@/types/search';
import CloseIcon from '@/images/icons/close.svg';

interface FilterChipsProps {
    chips: SearchFilterChip[];
    additionalChips?: SearchAdditionalFilterChip[];
    additionalSearchQuery?: string;
    removeChip: (chip: SearchFilterChip, additionalSearchQuery?: string) => void;
    loading: boolean;
}

export default function FilterChips({chips, additionalChips, removeChip, loading, additionalSearchQuery}: FilterChipsProps) {
    return (chips.length || additionalChips?.length) ? (
        <div className="flex flex-wrap gap-2 mb-6">
            {chips.length ? (
                <>
                    {chips.map((chip) => (
                        <button key={chip.value}
                                className="filter-chip filter-chip-primary"
                                disabled={loading}
                                onClick={() => removeChip(chip, additionalSearchQuery)}>
                            <span>{chip.label}</span>
                            <CloseIcon className="w-5 h-5"/>
                        </button>
                    ))}
                </>
            ) : <></>}
            {additionalChips?.length ? (
                <>
                    {additionalChips.map((additionalChip) => (
                        <button key={additionalChip.value}
                                className="filter-chip filter-chip-primary"
                                disabled={loading}
                                onClick={additionalChip.handleRemove}>
                            <span>{additionalChip.label}</span>
                            <CloseIcon className="w-5 h-5"/>
                        </button>
                    ))}
                </>
            ) : <></>}
        </div>
    ) : <></>;
}
