import {FieldAttributes, FormSchema, FormViolations} from '@/app/components/form/types';
import {FieldsetHTMLAttributes} from 'react';

export const findFieldViolation = (fieldViolations: FormViolations[], fieldName: string) => {
    const hasError = !!fieldViolations.find(v => v.propertyPath === fieldName);
    const errorMessage = fieldViolations.find(v => v.propertyPath === fieldName)?.title ?? null;

    return [hasError, errorMessage];
}

const parseNumber = (value: string): number => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
}

export const transformAttributes = (attr: Record<string, string> | undefined): FieldAttributes | undefined => {
    if (!attr) {
        return attr;
    }
    return {
        placeholder: attr.placeholder,
        maxLength: attr.maxLength !== undefined ? parseNumber(attr.maxLength) : undefined,
        minLength: attr.minLength !== undefined ? parseNumber(attr.minLength) : undefined,
        max: attr.max !== undefined ? parseNumber(attr.max) : undefined,
        min: attr.min !== undefined ? parseNumber(attr.min) : undefined,
        step: attr.step !== undefined ? parseNumber(attr.step) : undefined,
        autoComplete: attr.autoComplete,
        title: attr.title,
    };
}

export const transformFormData = (formData: FormData): Record<string, any> => {
    const obj: Record<string, any> = {};

    formData.forEach((value, key) => {
        // remove useFormState values
        if (key.startsWith('$ACTION_')) {
            return;
        }

        // handle multiple choice fields
        const isArrayKey = key.endsWith('[]');
        if (isArrayKey) {
            key = key.slice(0, -2);
        }

        const keys = key.split('.');
        keys.reduce((acc, currentKey, index) => {
            if (index === keys.length - 1) {
                // Handle existing keys
                if (acc[currentKey] !== undefined) {
                    if (Array.isArray(acc[currentKey])) {
                        acc[currentKey].push(value);
                    } else {
                        acc[currentKey] = [acc[currentKey], value];
                    }
                } else {
                    acc[currentKey] = isArrayKey ? [value] : value;
                }
            } else {
                acc[currentKey] = acc[currentKey] ?? (isNaN(Number(keys[index + 1])) ? {} : []);
            }
            return acc[currentKey];
        }, obj);
    });

    return obj;
};
