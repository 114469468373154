'use client'

import Alert from '@/images/icons/alert.svg';
import useTranslations from '@/hooks/translations';

interface FormErrorProps {
    error: string;
}

export default function FormError({ error }: FormErrorProps) {
    const { tc } = useTranslations();

    return (
        <div className="flex gap-3 items-center">
            <Alert className="w-6 h-auto text-red-200"/>
            <span className="text-sm text-red-200">
                {tc(error)}
            </span>
        </div>
    )
}