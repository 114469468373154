import ProductRequestFileUpload from '@/app/components/cms/customComponents/ProductRequestForm/form/ProductRequestFileUpload';
import {useFormStatus} from 'react-dom';
import useTranslations from '@/hooks/translations';
import React from 'react';

interface ProductFormRequestCustomFormContentProps {
    agbError: boolean;
    agbAccepted: boolean;
    setAgbAccepted: (agbAccepted: boolean) => void;
}

export default function ProductFormRequestCustomFormContent({
                                                                agbAccepted,
                                                                setAgbAccepted,
                                                            }: ProductFormRequestCustomFormContentProps) {
    const {pending} = useFormStatus();
    const {tc} = useTranslations();

    return (
        <div className="mt-12 flex flex-col gap-12">
            <ProductRequestFileUpload/>
            <div className="form-choice-expanded-group mb-0 wysiwyg">
                <div className="form-choice-expanded mb-0">
                    <label className="flex">
                        <input value={agbAccepted ? '1' : '0'}
                               onChange={() => setAgbAccepted(!agbAccepted)}
                               disabled={pending}
                               className="form-checkbox"
                               type="checkbox"
                        />
                        <div className="form-label-inline" dangerouslySetInnerHTML={{__html: tc('app.product_request_form.agb')}}>
                        </div>
                    </label>
                </div>
            </div>
            <div>
                <button type="submit" disabled={pending || !agbAccepted} className="btn btn-primary">
                    {tc('app.product_request_form.submit')}
                </button>
            </div>
        </div>
    )
}