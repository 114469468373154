'use client';

import React, { useEffect, useRef } from 'react';
import { FormState } from '@/app/components/form/types';
import IframeContent from '@/app/components/util/IframeContent/IframeContent';

interface SuccessMessagesProps {
    state: FormState,
}

export default function SuccessMessages({ state }: SuccessMessagesProps) {
    const messageDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!state.error) {
            return;
        }

        if (!messageDiv.current) {
            return;
        }

        messageDiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [state]);

    return (
        <div ref={messageDiv} className="scroll-mt-[var(--header-height)]">
            {state?.success && state.successResponse?.messages.map((message, i) => {
                if (message.message.type === 'string') {
                    return (
                        <div key={i} className="form-success-wrapper">
                            {message.message.data}
                        </div>
                    );
                } else if (message.message.type === 'snippet') {
                    return (
                        <IframeContent key={i} documentId={parseInt(message.message.data.toString())} />
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
}
