'use client';

import {useSwiper} from 'swiper/react';
import IconChevronLeft from '@/images/icons/chevron-left.svg';
import IconChevronRight from '@/images/icons/chevron-right.svg';

export default function InspirationTeaserSliderButtons() {
    const swiper = useSwiper();
    const styleClass = 'z-10 u-center bg-grey-150 hover:bg-primary transition-colors group rounded-full w-12 h-12';

    return (
        <div className={`w-full flex shrink-0 gap-2`}>
            <button className={styleClass} onClick={() => swiper.slidePrev()}>
                <IconChevronLeft className="h-6 w-6 transition-colors text-primary-text group-hover:text-white" />
            </button>
            <button className={styleClass} onClick={() => swiper.slideNext()}>
                <IconChevronRight className="h-6 w-6 transition-colors text-primary-text group-hover:text-white" />
            </button>
        </div>
    )
}