'use client';

import React from 'react';
import {BooleanFieldSchema, CommonFormWidgetProps} from '@/app/components/form/types';
import LabelWidget from '@/app/components/form/widgets/LabelWidget';
import {transformAttributes} from '@/app/components/form/util/helper';

interface IntegerWidgetProps extends CommonFormWidgetProps {
    field: BooleanFieldSchema
}

export default function CheckboxWidget({name, field}: IntegerWidgetProps) {
    return (
        <div className="form-choice-expanded-group">
            <div className="form-choice-expanded">
                <label>
                    <input
                        className="form-checkbox"
                        type="checkbox"
                        name={name}
                        defaultChecked={field.default}
                        {...transformAttributes(field.options.attr)}
                    />
                    <LabelWidget field={field} inline={true}/>
                </label>
            </div>
        </div>
    )
}
