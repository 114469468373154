'use client';

import {FormInterface} from '@/app/components/form/types';
import {Product} from '@/types/objects/product';
import {renderFormLayout} from '@/app/components/form/util/renderer';
import {useFormState} from 'react-dom';
import {FormEvent, useEffect, useRef, useState} from 'react';
import {ProductRequestFormState} from '@/types/productRequestForm';
import {handleProductRequestFormSubmit} from '@/app/actions/productRequestForm/actions';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import useTranslations from '@/hooks/translations';
import ProductFormRequestCustomFormContent
    from '@/app/components/cms/customComponents/ProductRequestForm/form/ProductFormRequestCustomFormContent';
import Alert from '@/app/components/ui/Alert';

interface ProductRequestFormWrapperProps {
    product: Product;
    formSchema: FormInterface;
}

export default function ProductRequestFormWrapper({product, formSchema}: ProductRequestFormWrapperProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [agbAccepted, setAgbAccepted] = useState<boolean>(false);
    const [agbError, setAgbError] = useState<boolean>(false);
    const {tc} = useTranslations();
    const initialFormState: ProductRequestFormState = {
        productId: product.id,
        formId: 1,
        outputWorkflowId: 1,
        generalViolations: [],
        fieldViolations: [],
        error: false,
        success: false,
        successResponse: null,
    }

    const [state, formAction] = useFormState(handleProductRequestFormSubmit, initialFormState);

    useEffect(() => {
        if ((state?.success || state?.error) && containerRef?.current) {
            const offset = containerRef.current.offsetTop - 120;

            window.scrollTo({
                top: offset,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [state?.error, state?.success, state?.fieldViolations]);

    const handleSubmit = (e: FormEvent) => {
        if (!agbAccepted) {
            e.preventDefault();
        }
    }

    return (
        <div ref={containerRef}>
            {state?.success ? (
                <div className="mb-10">
                    <Alert type={'success'}
                           title={tc('app.product_request_form.success.title')}
                           text={tc('app.product_request_form.success.text')}>
                    </Alert>
                </div>
            ) : <></>}
            {!state?.success ? (
                <>
                    {state?.error ? (
                        <div className="mb-10">
                            <Alert type={'error'}
                                   title={tc('app.product_request_form.error.title')}
                                   text={tc('app.product_request_form.error.text')}>
                            </Alert>
                        </div>
                    ) : <></>}
                    <div className="mb-12">
                        <h5>{tc('app.product_request_form.product.your_product')}</h5>
                        <div className="u-border-gradient-landscape overflow-hidden">
                            <div className="p-2 bg-white rounded flex gap-4 items-center">
                                {product.teaserImage ? (
                                    <div className="h-12 w-12 shrink-0 rounded overflow-hidden">
                                        <PimcoreImage image={product.teaserImage}
                                                      className="h-full w-full object-cover object-center"/>
                                    </div>
                                ) : <></>}
                                <div className="text-sm font-bold text-blue-300 hyphens-auto">{product.title}</div>
                            </div>
                        </div>
                    </div>
                    <form action={formAction} onSubmit={handleSubmit}>
                        {renderFormLayout(formSchema.schema, formSchema.schema.properties, state)}
                        <ProductFormRequestCustomFormContent agbError={agbError}
                                                             agbAccepted={agbAccepted}
                                                             setAgbAccepted={setAgbAccepted}/>
                    </form>
                </>
            ) : <></>}
        </div>
    )
}